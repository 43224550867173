import { useSearchParams } from "react-router-dom";
import serviceApi from "../../services/api/Service";
import { useEffect, useState } from "react";
import { IService } from "../../services/api/Service/types";

const useSearchPromotionalServices = (search: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [promotionalSearchAutocomplete, setPromotionalSearchAutocomplete] =
    useState<IService[]>([]);

  const fetchPromotinalSearchForAutocomplete = async () => {
    try {
      const response = await serviceApi.searchServices(search);
      setPromotionalSearchAutocomplete(response);
    } catch (error) {
      console.warn("Error fetching promotional services: ", error);
    }
  };

  useEffect(() => {
    if (search.length > 0) fetchPromotinalSearchForAutocomplete();
  }, [search]);

  return { promotionalSearchAutocomplete };
};

export default useSearchPromotionalServices;
