import PeopleIcon from "@mui/icons-material/People";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import StoreIcon from "@mui/icons-material/Store";
import WorkIcon from "@mui/icons-material/Work";
import { SxProps } from "@mui/material";

const singleTabStyle: SxProps = {
  textTransform: "none",
  color: "rgba(0,0,0,0.4)",
};

const tabsInfo = [
  { label: "Promoções", icon: <PriceCheckIcon />, style: singleTabStyle },
  {
    label: "Estabelecimentos",
    icon: <StoreIcon />,
    style: { ...singleTabStyle, minWidth: "30%" },
  },
  { label: "Serviços", icon: <WorkIcon />, style: singleTabStyle },
  { label: "Profissionais", icon: <PeopleIcon />, style: singleTabStyle },
];

export default tabsInfo;
