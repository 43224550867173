import { CSSProperties, useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SxProps,
  Alert,
} from "@mui/material";
import EmailConfirmation from "../../../assets/svg/email-confirmation.svg";
import InputMask from "react-input-mask";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/general/buttons/SecondaryButton";
import { userApi } from "../../../services";
import { DocType, UserRole } from "../../../types/types";
import { registerSchema } from "../../../utils/yupSchemas";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
  isCPFValid,
} from "../../../utils/stringUtils";
import { advanceStep, recedeStep } from "./functions";
import useCep from "../../../hooks/useCep";
import BackPageButton from "../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../styles/sharedComponentStyles";

const steps = ["Dados", "Endereço", "Confirmação"];

const Register = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const roleSearchParam = searchParams.get("role");

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [docNumber, setDocNumber] = useState("");
  const roleRef = useRef<HTMLSelectElement>(null);
  const docTypeRef = useRef<HTMLSelectElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmedPasswordRef = useRef<HTMLInputElement>(null);

  const personalDataRef = useRef({
    name: "",
    yearBirth: "",
    role: null,
    docType: null,
    docNumber: "",
    phoneNumber: "",
    email: "",
    password: "",
  });

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema) });

  const handleUserCreation = async () => {
    try {
      await userApi.createUser({
        name: personalDataRef.current.name,
        email: personalDataRef.current.email,
        password: personalDataRef.current.password,
        docNumber: personalDataRef.current.docNumber,
        docType: personalDataRef.current.docType,
        role: personalDataRef.current.role,
        phone: personalDataRef.current.phoneNumber,
        logradouro: streetRef.current.value,
        number: numberRef.current.value,
        district: districtRef.current.value,
        city: cityRef.current.value,
        state: stateRef.current.value,
        country: "Brasil",
        CEP: cep,
        yearBirth: personalDataRef.current.yearBirth,
        imageOriginal: "",
        imagePreview: "",
      } as Object);

      setActiveStep(2);
    } catch (error: any) {
      console.warn("Error creating user: ", error);
      setErrorMessage(error.response.data.message);
      setActiveStep(0);
    }
  };

  const foundedCepAddress = useCep(
    cep,
    streetRef,
    districtRef,
    cityRef,
    stateRef
  );

  const handleFirstStepSubmit = () => {
    if (!submittedOnce) setSubmittedOnce(true);

    if (isCPFValid(docNumber)) {
      personalDataRef.current = {
        name: nameRef.current.value,
        yearBirth: birthDateRef.current.value,
        role: roleRef.current.value,
        docType: docTypeRef.current.value,
        docNumber: docNumber,
        phoneNumber: phoneNumberRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };

      advanceStep(setActiveStep);
    }
  };

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      value={docNumber}
      onChange={(e) => setDocNumber(e.target.value)}
    >
      <TextField
        label="CPF*"
        fullWidth
        autoComplete="off"
        defaultValue={personalDataRef.current?.docNumber ?? ""}
        error={submittedOnce && !isCPFValid(docNumber)}
        helperText={
          submittedOnce && !isCPFValid(docNumber) ? "CPF inválido." : ""
        }
      />
    </InputMask>
  );

  const firstStep = (
    <form
      onSubmit={onSubmit(handleFirstStepSubmit)}
      style={{ ...styles.stepForm }}
    >
      <TextField
        label="Nome completo *"
        type="text"
        inputRef={nameRef}
        fullWidth
        {...register("name")}
        error={!!errors.name}
        helperText={errors.name?.message}
      />

      <FormControl fullWidth>
        <InputLabel>Tipo de conta *</InputLabel>
        <Select
          label="Tipo de conta"
          inputRef={roleRef}
          defaultValue={roleSearchParam ?? UserRole.COMERCIAL}
        >
          <MenuItem value={UserRole.CLIENT}>Cliente</MenuItem>
          <MenuItem value={UserRole.COMERCIAL}>Comercial</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Tipo de documento *</InputLabel>
        <Select
          label="Tipo de documento"
          inputRef={docTypeRef}
          defaultValue={DocType.CPF}
        >
          <MenuItem value={DocType.CPF}>CPF</MenuItem>
          <MenuItem value={DocType.PASSPORT}>Passaporte</MenuItem>
        </Select>
      </FormControl>

      {docTypeRef.current?.value === DocType.CPF ? CpfField : CpfField}

      <TextField
        label="Data de nascimento *"
        type="date"
        inputRef={birthDateRef}
        {...register("yearBirth")}
        error={!!errors.yearBirth}
        helperText={errors.yearBirth?.message}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />

      <InputMask mask="(99) 9.9999-9999" disabled={false} maskChar="_">
        <TextField
          label="Telefone"
          type="phone"
          fullWidth
          autoComplete="off"
          inputRef={phoneNumberRef}
          defaultValue={personalDataRef.current.phoneNumber ?? ""}
        />
      </InputMask>

      <TextField
        label="Email *"
        type="email"
        inputRef={emailRef}
        placeholder="exemplo@email.com"
        fullWidth
        {...register("email")}
        error={!!errors.email || errorMessage === "Email já cadastrado."}
      />

      <TextField
        label="Senha *"
        type="password"
        inputRef={passwordRef}
        fullWidth
        {...register("password")}
        error={!!errors.password}
        helperText={errors.password?.message}
      />
      <TextField
        label="Confirme sua senha *"
        type="password"
        inputRef={confirmedPasswordRef}
        fullWidth
        {...register("confirmedPassword")}
        error={!!errors.confirmedPassword}
        helperText={errors.confirmedPassword?.message}
      />
      {errorMessage !== null ? (
        <Alert severity="error" sx={{ ...styles.confirmButton, marginTop: 0 }}>
          {errorMessage}
        </Alert>
      ) : null}
      <Box sx={styles.confirmButton}>
        <PrimaryButton label="Prosseguir" icon={<CheckIcon />} />
      </Box>
    </form>
  );

  const secondStep = (
    <form onSubmit={onSubmit(handleUserCreation)} style={styles.stepForm}>
      <InputMask
        mask="99999-999"
        disabled={false}
        maskChar="_"
        value={cep}
        onChange={(e) => {
          setCep(e.target.value);
        }}
      >
        {<TextField label="CEP" fullWidth />}
      </InputMask>

      <Box sx={styles.doubleAddressLine}>
        <TextField
          label="Logradouro"
          type="text"
          inputRef={streetRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled={foundedCepAddress !== null}
        />
        <TextField
          label="N°"
          type="text"
          inputRef={numberRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          sx={{ width: "30%" }}
        />
      </Box>

      <TextField
        label="Bairro"
        type="text"
        inputRef={districtRef}
        InputLabelProps={{ shrink: true }}
        fullWidth
        disabled={foundedCepAddress !== null}
      />

      <Box sx={styles.doubleAddressLine}>
        <TextField
          label="Cidade"
          type="text"
          inputRef={cityRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled={foundedCepAddress !== null}
        />

        <TextField
          label="Estado"
          type="text"
          inputRef={stateRef}
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled={foundedCepAddress !== null}
          sx={{ width: "30%" }}
        />
      </Box>
      <Box sx={styles.confirmButton}>
        <PrimaryButton label="Confirmar endereço" icon={<CheckIcon />} />
      </Box>
    </form>
  );

  const thirdStep = (
    <Box sx={{ ...styles.stepForm, textAlign: "center", marginTop: "40px " }}>
      <Typography variant="h5" fontWeight="bold">
        Bem-vindo ao Demandoria!
      </Typography>

      <img
        src={EmailConfirmation}
        alt="email confirmation icon"
        width="120px"
        loading="lazy"
      />
      <Box>
        <Typography variant="h6">Verifique seu email para concluir.</Typography>
        <Typography>
          Enviamos o link de confirmação para&nbsp;
          <span>{personalDataRef.current.email}</span>.
        </Typography>
      </Box>

      <Box sx={{ ...styles.confirmButton, marginTop: "40px " }}>
        <PrimaryButton
          label="Ir para login"
          icon={<LoginIcon />}
          onClickAction={() => navigate("/login")}
        />
      </Box>
    </Box>
  );

  const renderContentByStep = () => {
    switch (activeStep) {
      case 0:
        return firstStep;
      case 1:
        return secondStep;
      default:
        return thirdStep;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...backButtonContainer, alignSelf: "flex-start" }}>
        <BackPageButton />
      </Box>

      <Box sx={styles.main}>
        <Typography variant="h4" fontWeight="bold">
          {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
            ? "Demandoria Beleza"
            : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
              ? "Demandoria PET"
              : "Demandoria Turismo"}
        </Typography>

        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <Typography variant="body2">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {renderContentByStep()}

        {activeStep === 1 ? (
          <Box sx={styles.backButtonContainer}>
            <SecondaryButton
              label="Voltar"
              onClickAction={() => recedeStep(setActiveStep)}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Register;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  } as SxProps,

  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    margin: "20px 0px 50px 0px",
    padding: "0px 30px",
  } as SxProps,

  stepForm: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginTop: "10px",
  } as CSSProperties,

  confirmButton: {
    width: "100%",
    marginTop: "20px",
  } as SxProps,

  doubleAddressLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  } as SxProps,

  backButtonContainer: {
    width: "100%",
    maxWidth: "450px",
  } as SxProps,
};
