import { Box, Rating, SxProps, Typography, Divider } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { lazy, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import defaultServiceImage from "../../../../assets/svg/defaultService.svg";
import defaultEmployeeImage from "../../../../assets/svg/defaultUser.svg";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import TimeRangeInterpreter from "../../../../components/comercial/TimeRangeInterpreter";
const CustomCard = lazy(
  () => import("../../../../components/general/CustomCard")
);
import ImageCarousel from "../../../../components/general/ImageCarousel";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IEmployee } from "../../../../services/api/Employee/types";
import { IStore } from "../../../../services/api/Store/types";
import { priceFormatter } from "../../../../utils/priceFormatter";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import {
  fetchEmployeesFromStore,
  fetchServiceStoresFromStore,
  fetchStore,
} from "../../../../utils/fetchEntities";
const ContactIcon = lazy(
  () => import("../../../../components/general/ContactIcon")
);

const StorePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStoreId = searchParams.get("id");

  const navigate = useNavigate();

  const [store, setStore] = useState<IStore | null>(null);

  const [selectedStoreServiceStores, setSelectedStoreServiceStores] = useState<
    IServiceStore[]
  >([]);

  const [selectedStoreEmployees, setSelectedStoreEmployees] = useState<
    IEmployee[]
  >([]);

  useEffect(() => {
    const fetchStoreData = async () => {
      await Promise.all([
        fetchStore(selectedStoreId!, setStore!),
        fetchServiceStoresFromStore(
          selectedStoreId!,
          setSelectedStoreServiceStores!
        ),
        fetchEmployeesFromStore(selectedStoreId!, setSelectedStoreEmployees!),
      ]);
    };

    fetchStoreData();
  }, []);

  const handleSelectServiceClick = (serviceStoreId: string) =>
    navigate(`/service?id=${serviceStoreId}`);

  const handleSelectEmployeeClick = (employeeId: string) =>
    navigate(`/employee?id=${employeeId}`);

  const renderStoreInfo = (
    <Box sx={{ ...styles.singleSection, textAlign: "justify" }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center">
        {store?.name}
      </Typography>
      {store?.listTags && <Typography>{store?.listTags}</Typography>}
      <Rating name="read-only" value={5} readOnly />
      <Typography>
        <DescriptionWrapper text={store?.description!} max={300} />
      </Typography>
    </Box>
  );

  const renderStoreLocation = (
    <Box sx={styles.singleSection}>
      <MapContainer
        zoom={20}
        center={[store?.lat, store?.lng]}
        style={{ width: "100%", height: "400px", borderRadius: "20px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker key={store?.id} position={[store?.lat, store?.lng]}></Marker>
      </MapContainer>
    </Box>
  );

  const renderStoreContacts = (
    <Box sx={{ ...styles.singleSection }}>
      <Typography variant="h5" fontWeight="bold">
        Entre em contato conosco
      </Typography>
      <Box sx={styles.contactsContainer}>
        <Box sx={styles.singleContact}>
          <ContactIcon icon={<LocationOnIcon color="primary" />} />
          <Typography>
            {store?.logradouro}, {store?.number}
          </Typography>
        </Box>
        <Box sx={styles.singleContact}>
          <ContactIcon icon={<PhoneIcon color="primary" />} />
          <Typography>{store?.phone}</Typography>
        </Box>
        <Box sx={styles.singleContact}>
          <ContactIcon icon={<AlternateEmailIcon color="primary" />} />
          <Typography>{store?.email}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderWorkingScale = (
    <Box sx={styles.singleSection}>
      <Typography variant="h5" fontWeight="bold">
        Horários de funcionamento
      </Typography>
      <TimeRangeInterpreter
        timeMonday={store?.timeMonday!}
        timeTuesday={store?.timeTuesday!}
        timeWednesday={store?.timeWednesday!}
        timeThursday={store?.timeThursday!}
        timeFriday={store?.timeFriday!}
        timeSaturday={store?.timeSaturday!}
        timeSunday={store?.timeSunday!}
      />
    </Box>
  );

  const renderOurServices = (
    <Box sx={styles.singleSection}>
      <Typography variant="h5" fontWeight="bold">
        Nossos serviços
      </Typography>
      {selectedStoreServiceStores.length > 0 ? (
        <Box sx={styles.horizontalScrollSection}>
          {selectedStoreServiceStores.map(
            (serviceStore: IServiceStore, index) => (
              <Box
                key={index}
                onClick={() => {
                  handleSelectServiceClick(serviceStore.id);
                }}
              >
                <CustomCard
                  photoURL={serviceStore.imagePreview[0]}
                  defaultPhoto={defaultServiceImage}
                  header={`R$ ${priceFormatter(serviceStore.price)}`}
                  primaryText={serviceStore.Service.name}
                  secondaryText={""}
                />
              </Box>
            )
          )}
        </Box>
      ) : (
        <Box>
          <Typography color="text.secondary">
            Este estabelecimento não possui nenhum serviço cadastrado.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderOurEmployees = (
    <Box sx={styles.singleSection}>
      <Typography variant="h5" fontWeight="bold">
        Nossos funcionários
      </Typography>
      {selectedStoreEmployees.length > 0 ? (
        <Box sx={styles.horizontalScrollSection}>
          {selectedStoreEmployees.map((employee: IEmployee, index) => (
            <Box
              key={index}
              onClick={() => {
                handleSelectEmployeeClick(employee.id);
              }}
            >
              <CustomCard
                photoURL={employee.User.imagePreview}
                defaultPhoto={defaultEmployeeImage}
                header={""}
                primaryText={employee.User.name}
                secondaryText={""}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Typography color="text.secondary">
            Este estabelecimento não possui nenhum serviço cadastrado.
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        overflowX: "hidden",
      }}
    >
      <ClientNavbar hideMidButton />

      {store?.imageOriginal.length > 0 ? (
        <Box width="100%" height="300px">
          <ImageCarousel imageUrls={store?.imageOriginal} />
        </Box>
      ) : null}

      <Box sx={styles.mainSectionContainer}>
        {store ? (
          <>
            {renderStoreInfo}
            {store ? renderStoreLocation : null}
            <Divider />
            {renderStoreContacts}
            <Divider />
            {renderWorkingScale}
            <Divider />
            {renderOurServices}
            {renderOurEmployees}
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default StorePage;

const styles = {
  mainSectionContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "50px 100px",
    gap: "80px",

    "@media(max-width:500px)": {
      margin: "50px 20px",
    },
  } as SxProps,

  storeImage: {
    width: "100%",
    height: "400px",
  } as SxProps,

  singleSection: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
  } as SxProps,

  contactsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
  } as SxProps,

  singleContact: { display: "flex", alignItems: "center", gap: 1 } as SxProps,

  horizontalScrollSection: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "100%",
    p: "10px 0px",
    gap: "20px",
  } as SxProps,
};
