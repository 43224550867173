import { Box, Typography, TextField, SxProps } from "@mui/material";
import { CSSProperties, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PasswordIcon from "@mui/icons-material/Password";
import PasswordRecoveryIcon from "../../../assets/img/PasswordRecovery.png";
import {
  firstStepPasswordRecoverySchema,
  secondStepPasswordRecoverySchema,
} from "../../../utils/yupSchemas";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../../services";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/general/buttons/SecondaryButton";
import BackPageButton from "../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../styles/sharedComponentStyles";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";

const PasswordRecovery = () => {
  const navigate = useNavigate();

  const [
    selectedPasswordEditionStepIndex,
    setSelectedPasswordEditionStepIndex,
  ] = useState(0);

  const [selectedEmailForPasswordEdition, setSelectedEmailForPasswordEdition] =
    useState("");

  const emailRef = useRef<HTMLInputElement>(null);

  const [isVerificationCodeValid, setIsVerificationCodeValid] =
    useState<boolean>(true);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState<boolean>(true);

  const verificationCodeRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);

  const isFirstStepActive = () => selectedPasswordEditionStepIndex === 0;

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isFirstStepActive()
        ? firstStepPasswordRecoverySchema
        : secondStepPasswordRecoverySchema
    ),
  });

  const handleFirstStepSubmit = async () => {
    setIsEmailAddressValid(true);

    setSelectedEmailForPasswordEdition(emailRef.current?.value);
    const emailRequestBody = { email: emailRef.current?.value };

    try {
      await userApi.sendPasswordRecoveryCode(emailRequestBody);
      setSelectedPasswordEditionStepIndex(1);
    } catch (error) {
      console.warn(
        `Error sending verification code to ${selectedEmailForPasswordEdition}: `,
        error
      );
      setIsEmailAddressValid(false);
    }
  };

  const handleSecondStepSubmit = async () => {
    const formattedVerificationCode =
      verificationCodeRef.current?.value.replaceAll(/[- _]/g, "");

    if (formattedVerificationCode?.length !== 6) {
      setIsVerificationCodeValid(false);
    } else {
      setIsVerificationCodeValid(true);

      const validationRequestBody = {
        token: formattedVerificationCode,
        email: selectedEmailForPasswordEdition,
        password: newPasswordRef.current?.value,
      };

      try {
        await userApi.validatePasswordRecovery(validationRequestBody);
        navigate("/login");
        alert("Senha alterada com sucesso!");
      } catch (error) {
        console.warn(`Error confirming the new password: `, error);
        setIsVerificationCodeValid(false);
      }
    }
  };

  const renderFirstStepPasswordEdition = (
    <form onSubmit={onSubmit(handleFirstStepSubmit)} style={styles.form}>
      <TextField
        label="Digite seu email"
        inputRef={emailRef}
        {...register("email")}
        error={!!errors.email ? errors.email : !isEmailAddressValid}
        helperText={
          errors.email?.message
            ? errors.email?.message
            : !isEmailAddressValid
              ? "Email não cadastrado."
              : ""
        }
        sx={{ height: "60px" }}
        fullWidth
      />
      <PrimaryButton label="Confirmar" icon={<></>} />
    </form>
  );

  const renderSecondStepPasswordEdition = (
    <form onSubmit={onSubmit(handleSecondStepSubmit)} style={styles.form}>
      <Box sx={styles.secondStepInnerContainer}>
        <InputMask
          mask="9  -  9  -  9  -  9  -  9  -  9"
          disabled={false}
          maskChar="_"
          inputRef={verificationCodeRef}
          error={!isVerificationCodeValid}
          helperText={
            !isVerificationCodeValid ? "Código de verificação inválido." : null
          }
        >
          <TextField label="Código de verificação" fullWidth />
        </InputMask>

        <TextField
          label="Digite a nova senha"
          type="password"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
          inputRef={newPasswordRef}
          sx={{ height: "60px" }}
          fullWidth
        />
      </Box>

      <Box sx={styles.secondStepInnerContainer}>
        <PrimaryButton label="Alterar senha" />
        <SecondaryButton
          label="Voltar"
          onClickAction={() => {
            setSelectedPasswordEditionStepIndex(0);
            setIsVerificationCodeValid(true);
          }}
        />
      </Box>
    </form>
  );

  return (
    <Box
      sx={{
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <ClientNavbar hideMidButton hideMenuButton/>
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box sx={styles.container}>
        <img
          src={PasswordRecoveryIcon}
          alt="password recovery"
          width={120}
          loading="lazy"
        />
        <Box sx={styles.titleContainer}>
          <Typography variant="h5" fontWeight="bold">
            {isFirstStepActive()
              ? "Recuperação de senha"
              : "Verifique o código enviado para:"}
          </Typography>
          <Typography>
            {isFirstStepActive()
              ? "Digite o email para enviarmos o código de verificação"
              : selectedEmailForPasswordEdition}
          </Typography>
        </Box>

        {isFirstStepActive()
          ? renderFirstStepPasswordEdition
          : renderSecondStepPasswordEdition}
      </Box>
    </Box>
  );
};

export default PasswordRecovery;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    margin: "50px 0px 70px 0px",
    padding: "0px 30px",
    alignItems: "center",
    justifyContent: "center",
  } as SxProps,

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    width: "100%",
    maxWidth: "400px",
  } as CSSProperties,

  titleContainer: {
    textAlign: "center",
    margin: "20px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  } as SxProps,

  secondStepInnerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  } as SxProps,
};
