import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
  SxProps,
} from "@mui/material";
import { generalWhite } from "../../../styles/colors";
import ClearIcon from "@mui/icons-material/Clear";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface ImageGalleryProps {
  images: HTMLCanvasElement[] | string[];
  setImages?: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
  setOriginalImages?: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
  setPreviewImages?: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
  interable?: boolean;
  emptyGalleryText?: ReactNode;
}

const ImageGallery = (props: ImageGalleryProps) => {
  const {
    images,
    setImages,
    setOriginalImages,
    setPreviewImages,
    interable,
    emptyGalleryText,
  } = props;

  const handleRemoveImageClick = (index: number) => {
    if (images.length > 0)
      setImages((previousImages) =>
        previousImages.filter((_, i) => i !== index)
      );
    setOriginalImages((previousImages) =>
      previousImages.filter((_, i) => i !== index)
    );
    setPreviewImages((previousImages) =>
      previousImages.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      {images.length > 0 ? (
        <ImageList cols={3} variant="quilted" rowHeight={300}>
          {images.map((image, index) => (
            <ImageListItem key={index}>
              <img
                src={
                  image instanceof HTMLCanvasElement ? image.toDataURL() : image
                }
                alt={`Image ${index}`}
                loading="lazy"
              />
              {interable ? (
                <ImageListItemBar
                  sx={styles.imageListItemBar}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: generalWhite }}
                      onClick={() => handleRemoveImageClick(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              ) : null}
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box sx={styles.emptyGalleryContainer}>
          <Box>{emptyGalleryText}</Box>
        </Box>
      )}
    </>
  );
};

export default ImageGallery;

const styles = {
  emptyGalleryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    margin: "40px 0px",
  } as SxProps,

  imageListItemBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
  } as SxProps,
};
