import { Box, Divider, SxProps, Typography } from "@mui/material";
import AddImageInput from "../AddImageInput";
import { ReactNode } from "react";

interface ImageGalleryHeaderProps {
  label: string;
  icon?: ReactNode;
  setImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  originalImages: HTMLCanvasElement[];
  previewImages: HTMLCanvasElement[];
}

const ImageGalleryHeader = (props: ImageGalleryHeaderProps) => {
  const { label, icon, setImages, originalImages, previewImages } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant="h6" fontWeight="bold">
          Galeria de fotos
        </Typography>
        <AddImageInput
          label={label}
          setImages={setImages}
          originalImages={originalImages}
          previewImages={previewImages}
          icon={icon}
        />
      </Box>
      <Divider />
    </Box>
  );
};

export default ImageGalleryHeader;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  } as SxProps,

  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as SxProps,
};
