import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  WEEKDAY_LABELS,
  WEEKDAY_LABELS_SHORT,
} from "../../../pages/turism/CEOPages/workingTimeConstants";
import { useState } from "react";
import {
  generalDarkGrey,
  invalidAvailabilityBorder,
  invalidAvailability as invalidAvailabilityColor,
  validAvailabilityBorder,
  validAvailability as validAvailabilityColor,
} from "../../../styles/colors";
import styled from "styled-components";
import WorkingScaleCaption from "./WorkingScaleCaption";

interface WorkingScaleSelectorProps {
  timeMonday: string[];
  setTimeMonday: (time: string[]) => void;
  timeTuesday: string[];
  setTimeTuesday: (time: string[]) => void;
  timeWednesday: string[];
  setTimeWednesday: (time: string[]) => void;
  timeThursday: string[];
  setTimeThursday: (time: string[]) => void;
  timeFriday: string[];
  setTimeFriday: (time: string[]) => void;
  timeSaturday: string[];
  setTimeSaturday: (time: string[]) => void;
  timeSunday: string[];
  setTimeSunday: (time: string[]) => void;
  possibleMondayTimes: string[];
  possibleTuesdayTimes: string[];
  possibleWednesdayTimes: string[];
  possibleThursdayTimes: string[];
  possibleFridayTimes: string[];
  possibleSaturdayTimes: string[];
  possibleSundayTimes: string[];
}

const WorkingScaleSelector = (props: WorkingScaleSelectorProps) => {
  const {
    timeMonday,
    setTimeMonday,
    timeTuesday,
    setTimeTuesday,
    timeWednesday,
    setTimeWednesday,
    timeThursday,
    setTimeThursday,
    timeFriday,
    setTimeFriday,
    timeSaturday,
    setTimeSaturday,
    timeSunday,
    setTimeSunday,
    possibleMondayTimes,
    possibleTuesdayTimes,
    possibleWednesdayTimes,
    possibleThursdayTimes,
    possibleFridayTimes,
    possibleSaturdayTimes,
    possibleSundayTimes,
  } = props;

  const [selectedWeekDayIndex, setSelectedWeekDayIndex] = useState(0);

  const selectedWeekDayTimes = [
    timeMonday,
    timeTuesday,
    timeWednesday,
    timeThursday,
    timeFriday,
    timeSaturday,
    timeSunday,
  ];

  const setSelectedWeekDayTimes = [
    setTimeMonday,
    setTimeTuesday,
    setTimeWednesday,
    setTimeThursday,
    setTimeFriday,
    setTimeSaturday,
    setTimeSunday,
  ];

  const possibleWeekTimes = [
    possibleMondayTimes,
    possibleTuesdayTimes,
    possibleWednesdayTimes,
    possibleThursdayTimes,
    possibleFridayTimes,
    possibleSaturdayTimes,
    possibleSundayTimes,
  ];

  const handleChangeSelectedWeekDayIndex = (_: any, newIndex: number) =>
    newIndex != null && setSelectedWeekDayIndex(newIndex);

  const handleChangeTimeAvailability = (dayTimeLabel: string) => {
    const temporaryDayTimeArray = [
      ...selectedWeekDayTimes[selectedWeekDayIndex],
    ];

    if (!temporaryDayTimeArray.includes(dayTimeLabel)) {
      temporaryDayTimeArray.push(dayTimeLabel);
    } else {
      const index = temporaryDayTimeArray.indexOf(dayTimeLabel);
      temporaryDayTimeArray.splice(index, 1);
    }

    temporaryDayTimeArray.sort((a, b) => {
      const parsedA = Number(a.split(":")[0]);
      const parsedB = Number(b.split(":")[0]);
      return parsedA - parsedB;
    });

    setSelectedWeekDayTimes[selectedWeekDayIndex](temporaryDayTimeArray);
  };

  return (
    <Container>
      <ToggleButtonGroup
        size="large"
        fullWidth
        value={selectedWeekDayIndex}
        onChange={handleChangeSelectedWeekDayIndex}
        exclusive
        color="primary"
      >
        {WEEKDAY_LABELS_SHORT.map((dayLabel, index) => (
          <ToggleButton
            key={index}
            sx={{ textTransform: "none", padding: "10px 5px" }}
            value={index}
          >
            <Typography variant="body2">{dayLabel}</Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <ToggleButtonGroup
        size="large"
        fullWidth
        sx={{ display: "flex", gap: 0.2, overflow: "auto" }}
      >
        {possibleWeekTimes[selectedWeekDayIndex].length > 0 ? (
          possibleWeekTimes[selectedWeekDayIndex].map((dayLabel, index) => {
            const isTimeAvailable =
              selectedWeekDayTimes[selectedWeekDayIndex].includes(dayLabel);

            return (
              <ToggleButton
                key={index}
                sx={{
                  textTransform: "none",
                  backgroundColor: isTimeAvailable
                    ? validAvailabilityColor
                    : invalidAvailabilityColor,
                  border: `1px solid ${
                    isTimeAvailable
                      ? validAvailabilityBorder
                      : invalidAvailabilityBorder
                  }`,
                  minWidth: "100px",
                  height: "80px",

                  ":hover": {
                    backgroundColor: isTimeAvailable
                      ? validAvailabilityColor
                      : invalidAvailabilityColor,
                  },
                }}
                onClick={() => handleChangeTimeAvailability(dayLabel)}
              >
                <Typography sx={styles.dayLabel}>
                  {dayLabel}
                </Typography>
              </ToggleButton>
            );
          })
        ) : (
          <Box>
            <Typography color={generalDarkGrey}>
              Nenhum horário disponível para{" "}
              {WEEKDAY_LABELS[selectedWeekDayIndex].toLowerCase()}
            </Typography>
          </Box>
        )}
      </ToggleButtonGroup>

      <WorkingScaleCaption />
    </Container>
  );
};

export default WorkingScaleSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 88vw;
`;

const styles = {
  dayLabel: {
    color: "rgba(0,0,0,0.5)",
    fontSize: "13pt",
    fontWeight: "bold"
  },
};
