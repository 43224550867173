import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
} from "@mui/material";
import { IStore } from "../../../services/api/Store/types";
import calculateDistanceBetweenCoordinates from "../../../utils/calculateDistanceBetweenCoordinates";
import { shortenLargeTexts } from "../../../utils/stringUtils";
import defaultStoreIcon from "../../../assets/svg/defaultStore.svg";
import { useRef } from "react";

interface NearbyStoreListItemProps {
  store: IStore;
  userCoordinates: [number, number];
  onClick: () => void;
  isSelected: boolean;
}

const NearbyStoreListItem = (props: NearbyStoreListItemProps) => {
  const itemRef = useRef(null);

  const { store, onClick, userCoordinates, isSelected } = props;

  const textColor = isSelected ? "rgba(0,0,0, 0.7)" : "text.primary";

  return (
    <ListItem key={`${store.id}_item`} sx={styles.container}>
      <ListItemButton
        onClick={() => {
          onClick();
          itemRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
        ref={itemRef}
        sx={{
          ...styles.button,
          ...(isSelected && {
            backgroundColor: "secondary.main",
            borderRadius: "5px",
          }),
        }}
      >
        <Avatar
          src={store.imagePreview[0] ?? defaultStoreIcon}
          imgProps={{ loading: "lazy" }}
          sx={styles.avatar}
        />
        <ListItemText
          primary={store.name}
          secondary={`aprox. ${calculateDistanceBetweenCoordinates(
            store.lat,
            store.lng,
            userCoordinates[0],
            userCoordinates[1]
          ).toFixed(2)} km`}
          primaryTypographyProps={{
            color: textColor,
            fontWeight: 500,
          }}
          secondaryTypographyProps={{
            color: textColor,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NearbyStoreListItem;

const styles = {
  container: {
    width: "100%",
    margin: 0,
    padding: 0,
  } as SxProps,

  button: {
    width: "100%",
    display: "flex",
    gap: "20px",
  } as SxProps,

  avatar: {
    width: "55px",
    height: "55px",
  } as SxProps,
};
