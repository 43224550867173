import { IconButton } from "@mui/material";
import { ReactNode } from "react";

interface MenuItemProps {
  onClick: any;
  icon: ReactNode;
}

const MenuItem = (props: MenuItemProps) => {
  const { onClick, icon } = props;

  return (
    <IconButton size="large" onClick={onClick}>
      {icon}
    </IconButton>
  );
};

export default MenuItem;
