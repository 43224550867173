import { getCoordinatesByAddress } from "../../../hooks/getCoodinatesByAddress";
import storeApi from "../../../services/api/Store";
import { IStore } from "../../../services/api/Store/types";
import { IUser } from "../../../services/api/User/types";

export const fetchNearbyStores = async (
  latitude: number,
  longitude: number
): Promise<IStore[]> => {
  let nearbyStores = [];

  try {
    const storesResponse = await storeApi.getNearbyStores(latitude, longitude);
    nearbyStores = storesResponse;
  } catch (error) {
    console.warn("Error fetching nearby stores: ", error);
  }

  return nearbyStores;
};

export const getUserAddressInCoordinates = async (user: IUser) => {
  const { logradouro, number, district, city, state, country } = user;

  const coordinates = await getCoordinatesByAddress({
    street: logradouro,
    number,
    district,
    city,
    state,
    country,
  });

  return {
    latitude: coordinates[0],
    longitude: coordinates[1],
  };
};
