import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import InputMask from "react-input-mask";
import { UserRole, UserStatus } from "../../../types/types";
import { useEffect, useRef, useState } from "react";
import { generalBrightGrey } from "../../../styles/colors";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { userApi } from "../../../services";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AvatarImageIconButton from "../../../components/general/buttons/AvatarImageIconButton";
import {
  deleteImageFromFirebase,
  uploadImagesToFirebase,
} from "../../../utils/firebase";
import BackPageButton from "../../../components/general/buttons/BackPageButton";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../hooks/getCoodinatesByAddress";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import { CURRENT_SUBDOMAIN } from "../../../utils/stringUtils";
import { backButtonContainer } from "../../../styles/sharedComponentStyles";
import useCep from "../../../hooks/useCep";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../utils/functions";
import LogoutIcon from "@mui/icons-material/Logout";

const AccountDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState<HTMLCanvasElement[]>([]);
  const [profileImageOriginal, setImageOriginal] = useState<
    HTMLCanvasElement[]
  >([]);
  const [profileImagePreview, setImagePreview] = useState<HTMLCanvasElement[]>(
    []
  );

  const profileImageOriginalUrl: string[] = [];
  const profileImagePreviewUrl: string[] = [];

  const [userUpdateLoading, setUserUpdateLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const [role, setRole] = useState<UserRole>(auth.user.role);
  const phoneRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(auth.user.CEP);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [succeededUpdate, setSucceededUpdate] = useState(false);

  const handleUpdateUser = async () => {
    setUserUpdateLoading(true);

    try {
      if (profileImage.length > 0) {
        if (
          auth.user.imageOriginal.startsWith(
            "https://firebasestorage.googleapis.com/v0/b/demandoria-auth.appspot.com/o/"
          )
        )
          await Promise.all([
            deleteImageFromFirebase(auth.user.imageOriginal),
            deleteImageFromFirebase(auth.user.imagePreview),
          ]);

        const originalUuids = await uploadImagesToFirebase(
          profileImageOriginal,
          profileImageOriginalUrl,
          `${CURRENT_SUBDOMAIN}/users/${auth.user.name}_${auth.user.id}`,
          "_original",
          []
        );

        await uploadImagesToFirebase(
          profileImagePreview,
          profileImagePreviewUrl,
          `${CURRENT_SUBDOMAIN}/users/${auth.user.name}_${auth.user.id}`,
          "_preview",
          originalUuids
        );
      }

      const coordinates = await getCoordinatesByAddress({
        street: streetRef.current.value ?? "",
        number: numberRef.current.value ?? "",
        district: districtRef.current.value ?? "",
        city: cityRef.current.value ?? "",
        state: stateRef.current.value ?? "",
        country: "Brasil",
      } as AddressProps);

      const updatedUser = {
        name: nameRef.current.value ?? "",
        role: role,
        phone: phoneRef.current.value ?? "",
        CEP: cep,
        logradouro: streetRef.current.value ?? "",
        number: numberRef.current.value ?? "",
        district: districtRef.current.value ?? "",
        city: cityRef.current.value ?? "",
        state: stateRef.current.value ?? "",
        country: "Brasil",
        imageOriginal: profileImageOriginalUrl[0],
        imagePreview: profileImagePreviewUrl[0],
        lat: Number(coordinates[0]),
        lng: Number(coordinates[1]),
        status: UserStatus.OK,
      };

      const updatedUserResponse = await userApi.updateUser(
        auth.user.id,
        updatedUser
      );

      auth.updateCurrentUser(updatedUserResponse);

      updatedUserResponse && setSucceededUpdate(true);
    } catch (error) {
      console.warn("Error updating user: ", error);
    }

    setUserUpdateLoading(false);
  };

  const foundedCepAddress = useCep(
    cep || "",
    streetRef || "",
    districtRef || "",
    cityRef || "",
    stateRef || ""
  );

  useEffect(() => {
    if (profileImage.length > 1)
      setProfileImage([profileImage[profileImage.length - 1]]);
  }, [profileImage]);

  useEffect(() => {
    if (profileImage.length > 1)
      setProfileImage([profileImage[profileImage.length - 1]]);
  }, [auth.user]);

  const renderSidebar = (
    <Box sx={styles.sidebarContainer}>
      <Box sx={styles.avatarContainer}>
        <Avatar
          src={
            profileImage.length === 0
              ? auth.user.imageOriginal
              : profileImage[0].toDataURL()
          }
          sx={{ ...styles.avatar, borderColor: "secondary.main" }}
        />
        <Tooltip title="Alterar foto de perfil">
          <AvatarImageIconButton
            icon={<CameraAltIcon />}
            setImages={setProfileImage}
            originalImages={profileImageOriginal}
            previewImages={profileImagePreview}
          />
        </Tooltip>
      </Box>

      <Typography fontSize="14pt" fontWeight="bold" textAlign="center">
        {auth.user.name}
      </Typography>

      <Box
        sx={{
          ...styles.sidebarInfoContainer,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box sx={styles.sidebarInfoContainer}>
          <BadgeIcon color="primary" />
          <Typography variant="body1" color="text.primary">
            {auth.user.docNumber}
          </Typography>
        </Box>

        <Box sx={styles.sidebarInfoContainer}>
          <EmailIcon color="primary" />
          <Typography variant="body1" textAlign="justify" color="text.primary">
            {auth.user.email}
          </Typography>
        </Box>
      </Box>

      <Button
        onClick={() => handleLogout(auth, navigate)}
        disableRipple
        sx={{ textTransform: "none" }}
        startIcon={<LogoutIcon />}
        color="error"
      >
        Sair
      </Button>
    </Box>
  );

  const renderPersonalData = (
    <>
      <Box sx={styles.sectionHeaders}>
        <Typography variant="h6">Editar dados pessoais</Typography>
        <Divider />
      </Box>
      <TextField
        label="Nome completo *"
        size="small"
        fullWidth
        inputRef={nameRef}
        defaultValue={auth.user.name}
      />

      {/* <TextField
        label="Data de nascimento *"
        type="date"
        InputLabelProps={{ shrink: true }}
        size="small"
        fullWidth
      /> */}
      <FormControl size="small" fullWidth>
        <InputLabel>Tipo de conta *</InputLabel>
        <Select
          label="Tipo de conta *"
          value={role}
          onChange={(e: any) => {
            setRole(e.target.value);
          }}
        >
          <MenuItem value={UserRole.CLIENT}>Cliente</MenuItem>
          <MenuItem value={UserRole.COMERCIAL}>Comercial</MenuItem>
        </Select>
      </FormControl>
      <InputMask mask="(99) 9.9999-9999" maskChar="_">
        <TextField
          label="Telefone"
          size="small"
          fullWidth
          inputRef={phoneRef}
          autoComplete="nope"
          defaultValue={auth.user.phone}
        />
      </InputMask>
      <Box sx={styles.sectionHeaders}>
        <Typography variant="h6">Editar endereço</Typography>
        <Divider />
      </Box>
      <InputMask
        mask="99999-999"
        disabled={false}
        maskChar="_"
        value={cep}
        onChange={(e) => {
          setCep(e.target.value);
        }}
      >
        <TextField size="small" label="CEP" fullWidth autoComplete="nope" />
      </InputMask>
      <Box sx={styles.addressDoubleFieldContainer}>
        <TextField
          size="small"
          label="Logradouro"
          sx={{ width: "75%" }}
          InputLabelProps={{ shrink: true }}
          inputRef={streetRef}
          disabled={foundedCepAddress !== null}
          defaultValue={auth.user.logradouro}
        />
        <TextField
          size="small"
          label="N°"
          sx={{ width: "25%" }}
          InputLabelProps={{ shrink: true }}
          inputRef={numberRef}
          defaultValue={auth.user.number}
        />
      </Box>

      <TextField
        size="small"
        label="Bairro"
        fullWidth
        InputLabelProps={{ shrink: true }}
        inputRef={districtRef}
        disabled={foundedCepAddress !== null}
        defaultValue={auth.user.district}
      />

      <Box sx={styles.addressDoubleFieldContainer}>
        <TextField
          size="small"
          label="Cidade"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputRef={cityRef}
          disabled={foundedCepAddress !== null}
          defaultValue={auth.user.city}
        />
        <TextField
          size="small"
          label="Estado"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputRef={stateRef}
          disabled={foundedCepAddress !== null}
          defaultValue={auth.user.state}
        />
      </Box>
      <Box sx={styles.confirmUpdateButton}>
        <PrimaryButton
          label="Confirmar edição"
          onClickAction={handleUpdateUser}
          isLoading={userUpdateLoading}
          icon={<EditIcon />}
          styles={{ height: "50px" }}
        />
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <Snackbar
        open={succeededUpdate}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSucceededUpdate(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Usuário atualizado com sucesso!
        </Alert>
      </Snackbar>
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.sidebarOuterBox}>{renderSidebar}</Box>
        <Box sx={styles.sectionOuterBox}>{renderPersonalData}</Box>
      </Box>
    </Box>
  );
};

export default AccountDetails;

const styles = {
  mainContainer: {
    display: "flex",
    padding: "15px",
    borderRadius: "15px",
    justifyContent: "space-between",
    gap: 1,

    "@media(max-width:700px)": {
      margin: " 0px",
    },
    "@media(max-width:900px)": {
      flexDirection: "column",
      alignItems: "center",
      gap: 2,
    },
  } as SxProps,

  sidebarOuterBox: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
  } as SxProps,

  sidebarInfoContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    color: "text.secondary",
  } as SxProps,

  sectionOuterBox: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    gap: 3,
    border: `1px solid ${generalBrightGrey}`,
    padding: "30px 80px",
    borderRadius: "13px",
    margin: "0px 50px",

    "@media(max-width:800px)": {
      width: "100%",
      padding: "10px 30px",
    },
  } as SxProps,

  sectionHeaders: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    margin: "15px 0px",
  } as SxProps,

  sidebarContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginBottom: "20px ",
    textAlign: "left",
    alignItems: "center",
  } as SxProps,

  avatar: {
    minWidth: "120px",
    minHeight: "120px",
    borderWidth: "4px",
    borderStyle: "solid",

    "@media (max-width:700px)": {
      width: "75px",
      height: "75px",
      borderWidth: "3px",
    },
  } as SxProps,

  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as SxProps,

  addressDoubleFieldContainer: {
    display: "flex",
    gap: 1.5,
  } as SxProps,

  confirmUpdateButton: {
    margin: "20px 0px",
  } as SxProps,
};
