import {
  Box,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useSearchParams } from "react-router-dom";
import useFetchExcursion from "../../../../hooks/entities/useFetchExcursion";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
import styled from "styled-components";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { ExcursionServiceType } from "../../../../types/types";
import NearMeIcon from "@mui/icons-material/NearMe";
import { formatDateString } from "../../../../utils/stringUtils";
import EventIcon from "@mui/icons-material/Event";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { lazy } from "react";
const ExcursionServiceCard = lazy(
  () => import("../../../../components/comercial/ExcursionServiceCard")
);
const ImageCarousel = lazy(
  () => import("../../../../components/general/ImageCarousel")
);

const TITLE_FONT_WEIGHT = 600;
const SERVICE_ICON_STYLES: SxProps = { width: "30px", height: "30px" };

const ExcursionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { excursion, loading } = useFetchExcursion(searchParams.get("id"));

  const mainExcursionInfo = (
    <InnerContainer>
      <Box>
        <Typography variant="h5" fontWeight="bold">
          {excursion?.title}
        </Typography>
        <Typography variant="h6">{excursion?.mainCategory}</Typography>
      </Box>

      <ExcursionShowcase>
        <ImageCarouselContainer>
          <ImageCarousel imageUrls={excursion?.imageOriginal} />
        </ImageCarouselContainer>

        <ShowcaseDetails>
          <DetailContainer>
            <AssignmentIndIcon />
            <Typography fontWeight={TITLE_FONT_WEIGHT}>
              Agente:{" "}
              <span style={{ fontWeight: "normal" }}>{excursion?.agent}</span>
            </Typography>
          </DetailContainer>

          <DetailContainer>
            <ConfirmationNumberIcon />
            <Typography>{`${2} vagas disponíveis`}</Typography>
          </DetailContainer>

          <DetailContainer>
            <EventIcon />
            <Typography>{`${formatDateString(
              excursion?.startDate
            )} - ${formatDateString(excursion?.endDate)}`}</Typography>
          </DetailContainer>

          <Typography
            color="primary"
            fontWeight="bold"
            variant="h5"
            sx={{ margin: "10px 0px" }}
          >{`R$ ${priceFormatter(excursion?.price * 100)}`}</Typography>

          <Box sx={{ margin: "10px 0px" }}>
            <PrimaryButton
              label="Adicionar carrinho"
              icon={<AddShoppingCartIcon />}
            />
          </Box>
        </ShowcaseDetails>
      </ExcursionShowcase>

      <HeaderAndContent>
        <Typography variant="h6" fontWeight={TITLE_FONT_WEIGHT}>
          Descrição
        </Typography>
        <Typography textAlign="justify">
          <DescriptionWrapper text={excursion?.description!} max={200} />
        </Typography>
      </HeaderAndContent>
    </InnerContainer>
  );

  const excursionDetails = (
    <ServicesAndRestrictionContainer>
      <HeaderAndContent>
        <Typography variant="h6" fontWeight={TITLE_FONT_WEIGHT}>
          Quais serviços estão inclusos?
        </Typography>
        <ServicesContainer>
          <ExcursionServiceCard
            icon={<HotelIcon sx={SERVICE_ICON_STYLES} />}
            serviceType={ExcursionServiceType.ACCOMMODATION}
            service={excursion?.accommodationService}
          />

          <ExcursionServiceCard
            icon={<DirectionsBusIcon sx={SERVICE_ICON_STYLES} />}
            serviceType={ExcursionServiceType.TRANSPORT}
            service={excursion?.transportService}
          />

          <ExcursionServiceCard
            icon={<RestaurantIcon sx={SERVICE_ICON_STYLES} />}
            serviceType={ExcursionServiceType.FOOD}
            service={excursion?.foodService}
          />
        </ServicesContainer>
      </HeaderAndContent>
      <HeaderAndContent>
        <Typography variant="h6" fontWeight={TITLE_FONT_WEIGHT}>
          Restrições
        </Typography>
        <Typography textAlign="justify">• {excursion?.restriction}</Typography>
      </HeaderAndContent>
    </ServicesAndRestrictionContainer>
  );

  const geographicInfo = (
    <GeographicInfoContainer>
      <Typography variant="h6" fontWeight={TITLE_FONT_WEIGHT}>
        {`${excursion?.city} - ${excursion?.state} (${excursion?.region}) | ${excursion?.country}`}
      </Typography>
      <RouteContainer>
        <NearMeIcon color="secondary" />

        <Typography fontWeight={TITLE_FONT_WEIGHT}>
          Rota da viagem:{" "}
          <span style={{ fontWeight: "normal" }}>
            {excursion?.router.map((route, index) => {
              return index === excursion?.router.length - 1
                ? `${route}.`
                : `${route}, `;
            })}
          </span>
        </Typography>
      </RouteContainer>
    </GeographicInfoContainer>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Container>
        {!loading ? (
          <>
            {excursion ? (
              <InnerContainer>
                {mainExcursionInfo}
                <Divider sx={{ width: "100%" }} />
                {excursionDetails}
                <Divider sx={{ width: "100%" }} />
                {geographicInfo}
              </InnerContainer>
            ) : null}
          </>
        ) : (
          <CircularProgress />
        )}
      </Container>
    </Box>
  );
};

export default ExcursionPage;

const Container = styled.div`
  padding: 10px 60px 60px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    padding: 10px 30px 60px 30px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const ExcursionShowcase = styled.div`
  display: flex;
  gap: 60px;
  margin-top: -30px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ImageCarouselContainer = styled.div`
  width: 70%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ShowcaseDetails = styled.div`
  width: 30%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }
`;

const RouteContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DetailContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ServicesAndRestrictionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const ServicesContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const GeographicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: justify;
`;

const HeaderAndContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
