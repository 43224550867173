"use client";
import styled from "styled-components";
import { generalBrightGrey } from "../../../styles/colors";
import { useState } from "react";

const INFERIOR_HEIGHT_LIMIT = 25;
const SUPERIOR_HEIGHT_LIMIT = 90;

const BottomSheet = ({ children }: any) => {
  const [currentHeight, setCurrentHeight] = useState(INFERIOR_HEIGHT_LIMIT);

  const handleDrag = (e: any) => {
    let startY = e.clientY;
    let newHeight;

    document.onpointermove = dragMove;

    function dragMove(e: any) {
      const delta = startY - e.clientY;
      newHeight = currentHeight + (delta / window.innerHeight) * 100;

      newHeight = Math.max(
        INFERIOR_HEIGHT_LIMIT,
        Math.min(newHeight, SUPERIOR_HEIGHT_LIMIT)
      );
      setCurrentHeight(newHeight);
    }

    document.onpointerup = dragEnd;

    function dragEnd() {
      document.onpointermove = null;
      document.onpointerup = null;
    }
  };

  return (
    <Container>
      <Content height={currentHeight}>
        <Header
          onPointerDown={handleDrag}
          onDoubleClick={() => setCurrentHeight(INFERIOR_HEIGHT_LIMIT)}
        >
          <DragIcon>
            <span />
          </DragIcon>
        </Header>
        <Body>{children}</Body>
      </Content>
    </Container>
  );
};

export default BottomSheet;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;

const Content = styled.div<{ height: number }>`
  position: absolute;
  max-width: 800px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: ${({ height }) => height}%;
  padding: 15px 0px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 10px 15px rgba(46, 51, 71, 0.4);
  background-color: #fff;
  pointer-events: all;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  touch-action: none;
  cursor: grab;
`;

const DragIcon = styled.div`
  user-select: none;
  padding: 15px;
  margin-top: -15px;

  & span {
    height: 5px;
    width: 50px;
    display: block;
    background-color: ${generalBrightGrey};
    border-radius: 20px;
  }
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.05);
`;
