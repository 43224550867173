import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

interface DateSelectorProps {
  value: Dayjs;
  setValue: Dispatch<SetStateAction<Dayjs>>;
  label: string;
  disablePast?: boolean;
  minDate?: Dayjs;
}

const DateSelector = (props: DateSelectorProps) => {
  const { value, setValue, label, disablePast, minDate } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        label={label}
        slotProps={{
          textField: {
            size: "small",
            sx: {
              width: "100%",
            },
          },
        }}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
        dayOfWeekFormatter={(day: string) => {
          return day;
        }}
        disablePast={disablePast}
        minDate={minDate}
      />
    </LocalizationProvider>
  );
};

export default DateSelector;
