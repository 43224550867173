import axios from "axios";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?q=";

export interface AddressProps {
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  region?: string;
  country: string;
}

export const getCoordinatesByAddress = async (props: AddressProps) => {
  let latitude: string = "";
  let longitude: string = "";

  try {
    const response = await axios.get(
      `${NOMINATIM_BASE_URL}${`${props.street}, ${props.number} - ${props.district}, ${props.city}/${props.state}`}&format=json`
    );

    latitude = response.data[0].lat;
    longitude = response.data[0].lon;
  } catch (error) {
    console.warn("Error fetching nominatim coordinates by address: ", error);
  }

  return [Number(latitude), Number(longitude)];
};
