import { Box, SxProps, Typography } from "@mui/material";
import { IRating } from "../../../services/api/Rating/types";
import ServiceRatingListItem from "./ServiceRatingListItem";

interface ServiceRatingListProps {
  ratings: IRating[];
}

const ServiceRatingList = (props: ServiceRatingListProps) => {
  const { ratings } = props;

  return (
    <Box>
      {ratings.length > 0 ? (
        <Box sx={styles.ratingsList}>
          {ratings.map((rating, index) => (
            <ServiceRatingListItem rating={rating} key={index} />
          ))}
        </Box>
      ) : (
        <Box>
          <Typography color="text.secondary">
            Ainda não há avaliações para este serviço.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ServiceRatingList;

const styles = {
  ratingsList: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "20px",
    width: "100%",
    textAlign: "justify",
  } as SxProps,
};
