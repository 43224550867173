import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { formatDateAndTimeDayDateToString } from "../ScheduilingPage/timeUtils";
import emptyCartImage from "../../../../assets/img/empty-cart.png";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import cartApi from "../../../../services/api/Cart";
import { ICart } from "../../../../services/api/Cart/types";
import cartItemApi from "../../../../services/api/CartItem";
import { generalBrightGrey } from "../../../../styles/colors";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { ICartItem } from "../../../../services/api/CartItem/type";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";

const ShoppingCart = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  const [userCart, setUserCart] = useState<ICart | null>(null);
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [isCartLoading, setIsCartLoading] = useState(false);

  const fetchUserShoppingCart = async () => {
    setIsCartLoading(true);
    try {
      const userCartResponse = await cartApi.getUserCart(auth.user.id);

      setUserCart(userCartResponse);
      setCartItems(userCartResponse.items);
    } catch (error) {
      console.warn("Error fetching user's cart: ", error);
    }
    setIsCartLoading(false);
  };

  const handleClearCartClick = async () => {
    if (userCart?.items?.length > 0) {
      const wantsToClearCart = confirm(
        "Tem certeza que deseja excluir todos os itens do carrinho?"
      );

      if (wantsToClearCart) {
        try {
          for (let index = 0; index < userCart?.items?.length; index++)
            await cartItemApi.deleteCartItem(userCart?.items[index]?.id);

          await cartApi.deleteCart(userCart?.id);
          fetchUserShoppingCart();
        } catch (error) {
          console.warn("Error cleaning items from cart: ", error);
        }
      }
    }
  };

  const handleRemoveCartItem = async (id: string) => {
    const wantsToDeleteCartItem = confirm(
      `Tem certeza que deseja excluir item do carrinho?`
    );

    if (wantsToDeleteCartItem) {
      try {
        await cartItemApi.deleteCartItem(id);
      } catch (error) {
        console.error("Error removing cart item", error);
      }
      setCartItems([]);
      fetchUserShoppingCart();
    }
  };

  useEffect(() => {
    fetchUserShoppingCart();
  }, [auth.user.id]);

  const ScheduiledServicesList = (
    <TableContainer sx={styles.cartContainer}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Serviço</TableCell>
            <TableCell align="center">Agendamento</TableCell>
            <TableCell align="right">Preço</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.length > 0
            ? cartItems.map((item) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={item.id}
                >
                  <TableCell component="th" scope="row">
                    <Box>
                      <ListItem disablePadding>
                        <ListItemAvatar>
                          <Avatar src={item.imagePreview} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={`Funcionário: ${item.employeeName} (${userCart.storeName})`}
                        />
                      </ListItem>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {formatDateAndTimeDayDateToString(item.date.toISOString())}
                  </TableCell>
                  <TableCell align="right">
                    R$ {priceFormatter(item.price)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        handleRemoveCartItem(item.id);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Box
        margin="10px 80px"
        sx={{
          "@media (max-width: 800px)": {
            margin: "10px 30px",
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight="bold">
              Seu carrinho de compras
            </Typography>
            <Typography color="text.secondary">
              {isCartLoading
                ? "Carregando..."
                : ` Há
              ${
                cartItems.length === 1
                  ? ` 1 item`
                  : ` ${cartItems.length} itens`
              }`}
            </Typography>
          </Box>
          <Button
            sx={{
              textTransform: "none",
              display: cartItems.length > 0 ? "flex" : "none",
            }}
            onClick={() => {
              void handleClearCartClick();
            }}
          >
            Limpar tudo
          </Button>
        </Box>
        {!isCartLoading ? (
          <>
            <Box margin="30px 0px">
              <Box>
                {cartItems.length > 0 ? (
                  ScheduiledServicesList
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    padding="60px 0px"
                    gap={4}
                  >
                    <img src={emptyCartImage} alt="Empty cart" width={180} />
                    <Typography variant="h6" color="text.secondary">
                      Seu carrinho está vazio.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                sx={{
                  display: cartItems.length > 0 ? "flex" : "none",
                  margin: "30px 0px",
                }}
              >
                <Typography variant="h6">Total:</Typography>
                <Typography variant="h5" fontWeight="bold">
                  R$ {priceFormatter(userCart?.total ?? 0)}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                mb: "40px",
                display: cartItems.length > 0 ? "flex" : "none",
              }}
              alignItems="center"
            >
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  navigate("/search");
                }}
                sx={{
                  textTransform: "none",
                }}
              >
                Continuar comprando
              </Button>
              <Button
                variant="contained"
                disableElevation
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/payment")}
              >
                Finalizar compra
              </Button>
            </Box>
          </>
        ) : (
          <Box
            width="100%"
            height="60vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShoppingCart;

const styles = {
  cartContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },
};
