import { createContext, Dispatch, SetStateAction } from "react";
import { IStore } from "../../services/api/Store/types";

type ManagerStoresContextType = {
  stores: IStore[];
  setStores: Dispatch<SetStateAction<IStore[]>>;
};

const ManagerStoresContext = createContext<ManagerStoresContextType | null>(
  null
);

export default ManagerStoresContext;
