import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, ReactNode, SetStateAction } from "react";

const today = dayjs();

interface DmCalendarProps {
  selectedDate: Dayjs;
  setSelectedDate: Dispatch<SetStateAction<Dayjs>>;
  highlightedDays: string[];
  returnFunction: (props: any) => ReactNode;
}

const DmCalendar = (calendarProps: DmCalendarProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DateCalendar
        defaultValue={today}
        disablePast
        slots={{
          day: calendarProps.returnFunction,
        }}
        dayOfWeekFormatter={(day) => {}}
        value={calendarProps.selectedDate}
        onChange={(newDate) => {
          calendarProps.setSelectedDate(newDate);
        }}
      />
    </LocalizationProvider>
  );
};

export default DmCalendar;
