import { useEffect } from "react";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { fetchManagerStores } from "../../utils/fetchEntities";
import useManagerStores from "../context-hooks/useManagerStores";

const useFetchManagerStores = () => {
  const auth = useAuth();

  const { setStores } = useManagerStores();

  const fetchStores = () =>
    fetchManagerStores(auth.user.id).then((storesResponse) =>
      setStores(storesResponse)
    );

  useEffect(() => {
    if (auth.user.id) fetchStores();
  }, [auth.user.id]);

  return { fetchStores };
};

export default useFetchManagerStores;
