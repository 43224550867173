import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  SxProps,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

interface CEOInterfaceHeaderProps {
  title: string;
  buttonLabel?: string;
  buttonIcon?: any;
  onClick?: any;
}

const CEOInterfaceHeader = (props: CEOInterfaceHeaderProps) => {
  const { title, buttonLabel, buttonIcon, onClick } = props;

  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        {buttonLabel && buttonIcon ? (
          <Button
            onClick={onClick}
            disableRipple
            sx={{ textTransform: "none" }}
          >
            <IconButton disableRipple>
              <Icon color="primary">{buttonIcon}</Icon>
            </IconButton>
            {!isMobile && buttonLabel}
          </Button>
        ) : null}
      </Box>
      <Divider />
    </Box>
  );
};

export default CEOInterfaceHeader;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  } as SxProps,

  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as SxProps,
};
