import { Box, Typography } from "@mui/material";
import {
  generalWhite,
  generalDarkGrey,
} from "../../../styles/colors";
import DemandoriaIcon from "../../../assets/svg/logo-full.svg";
import styles from "./styles";
import Link from '@mui/material/Link';

const Footer = () => {
  return (
    <section style={styles.footerSection}>
      <Box sx={styles.footerContainer}>
        <Box sx={styles.main}>
          <Box sx={styles.logoContainer}>
            <img src={DemandoriaIcon} alt="Demandoria Icon" width="20px" />
            <Typography variant="h6" color={generalWhite} fontWeight="bold">
              Demandoria
            </Typography>
          </Box>
          <Typography color={generalDarkGrey} variant="body2">
            Copyright © {new Date().getFullYear()} Demandoria - version 1.0
          </Typography>
        </Box>
        <Typography color={generalDarkGrey} variant="body2">
          Contact:{" "}
          <Link href="mailto:demandoria@gmail.com" color="secondary.main" underline="none">
            demandoria@gmail.com
          </Link>
        </Typography>
      </Box>
    </section>
  );
};

export default Footer;
