import { Box, Typography } from "@mui/material";

const days = [
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
  "Domingo",
];

interface TimeRangeInterpreterProps {
  timeMonday: string[];
  timeTuesday: string[];
  timeWednesday: string[];
  timeThursday: string[];
  timeFriday: string[];
  timeSaturday: string[];
  timeSunday: string[];
}

const TimeRangeInterpreter = (props: TimeRangeInterpreterProps) => {
  const formatWeekdayTime = (daytime: string[]): string[] => {
    const dayTimesStringArray: string[] = [];

    if (daytime.length === 0) {
      return dayTimesStringArray;
    }

    let startHour = Number(daytime[0].split(":")[0]);
    let endHour = startHour;

    for (let i = 1; i < daytime.length; i++) {
      const currentHour = Number(daytime[i].split(":")[0]);

      if (currentHour !== endHour + 1) {
        if (startHour !== endHour) {
          dayTimesStringArray.push(`${startHour}:00 - ${endHour}:00`);
        } else {
          dayTimesStringArray.push(`${startHour}:00`);
        }

        startHour = currentHour;
      }

      endHour = currentHour;
    }
    startHour !== endHour
      ? dayTimesStringArray.push(`${startHour}:00 - ${endHour}:00`)
      : dayTimesStringArray.push(`${startHour}:00`);

    return dayTimesStringArray;
  };

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        gap={1}
        sx={{
          "@media (max-width: 1100px)": {
            flexDirection: "column",
            gap: 3,
          },
        }}
      >
        {days.map((day, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h6">{day}</Typography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {index === 0
                ? formatWeekdayTime(props.timeMonday).map((interval, index) => (
                    <Typography variant="body1" key={index}>
                      {interval}
                    </Typography>
                  ))
                : index === 1
                  ? formatWeekdayTime(props.timeTuesday).map(
                      (interval, index) => (
                        <Typography variant="body1" key={index}>
                          {interval}
                        </Typography>
                      )
                    )
                  : index === 2
                    ? formatWeekdayTime(props.timeWednesday).map(
                        (interval, index) => (
                          <Typography variant="body1" key={index}>
                            {interval}
                          </Typography>
                        )
                      )
                    : index === 3
                      ? formatWeekdayTime(props.timeThursday).map(
                          (interval, index) => (
                            <Typography variant="body1" key={index}>
                              {interval}
                            </Typography>
                          )
                        )
                      : index === 4
                        ? formatWeekdayTime(props.timeFriday).map(
                            (interval, index) => (
                              <Typography variant="body1" key={index}>
                                {interval}
                              </Typography>
                            )
                          )
                        : index === 5
                          ? formatWeekdayTime(props.timeSaturday).map(
                              (interval, index) => (
                                <Typography variant="body1" key={index}>
                                  {interval}
                                </Typography>
                              )
                            )
                          : formatWeekdayTime(props.timeSunday).map(
                              (interval, index) => (
                                <Typography variant="body1" key={index}>
                                  {interval}
                                </Typography>
                              )
                            )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default TimeRangeInterpreter;
