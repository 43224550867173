import { CSSProperties, Dispatch, useEffect } from "react";
import { MapContainer, Marker, Popup, useMap } from "react-leaflet";
import Leaflet from "leaflet";
import { TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { IStore } from "../../../services/api/Store/types";
import { MapCard } from "../MapHoverCard";
import calculateDistanceBetweenCoordinates from "../../../utils/calculateDistanceBetweenCoordinates";

const MAP_URL = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";
const ATTRIBUITION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

interface MapProps {
  stores: IStore[];
  userCoordinates: [number, number];
  mapCenter: [number, number];
  setMap: Dispatch<any>;
}

const NearbyStoresMap = (props: MapProps) => {
  const INITIAL_MAP_ZOOM = 13;
  const MAX_MAP_ZOOM = 30;

  return (
    <MapContainer
      center={props.mapCenter}
      zoom={INITIAL_MAP_ZOOM}
      easeLinearity={0.35}
      ref={props.setMap}
      maxZoom={MAX_MAP_ZOOM}
      style={styles.map}
    >
      <MarkerClusterGroup chunkedLoading>
        <TileLayer attribution={ATTRIBUITION} url={MAP_URL} />
        {props.stores.map((store: IStore) => (
          <Marker
            key={store.id}
            position={[store.lat, store.lng]}
            title={store.name}
          >
            <Popup>
              <MapCard
                imageUrl={store.imagePreview[0]}
                primaryText={`${store.name}`}
                secondaryText={`${store.district}, ${
                  store.city
                } (${calculateDistanceBetweenCoordinates(
                  store.lat,
                  store.lng,
                  props.userCoordinates[0],
                  props.userCoordinates[1]
                ).toFixed(2)} km)`}
                selectedStore={store}
              />
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
      <MapCenterHandler mapCenter={props.mapCenter} />
    </MapContainer>
  );
};

export default NearbyStoresMap;

const styles = {
  map: {
    height: "90vh",
    zIndex: 0,
  } as CSSProperties,
};

const MapCenterHandler = (props) => {
  const { mapCenter } = props;

  const map = useMap();

  useEffect(() => {
    if (mapCenter)
      map.setView(Leaflet.latLng(mapCenter[0], mapCenter[1]), map.getZoom(), {
        animate: true,
      });
  }, [mapCenter]);

  return null;
};
