import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import { IStore } from "../../../../../services/api/Store/types";
import { generalBrightGrey } from "../../../../../styles/colors";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import defaultStoreImage from "../../../../../assets/svg/defaultStore.svg";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import { handleDeleteStore } from "./functions";
import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";

const Stores = () => {
  const navigate = useNavigate();

  cleanLocalStorage();

  const managerStoresContext = useManagerStores();
  const { fetchStores } = useFetchManagerStores();

  const handleSelectStore = (store: IStore) =>
    setLocalStorageItem("selectedStore", store);

  const handleSeeStoreDetailsClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/stores/details?id=${store.id}`);
  };

  const handleEditStoreClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/stores/edit?id=${store.id}`);
  };

  const handleDeleteManagerStore = async (store: IStore) => {
    await handleDeleteStore(store);
    fetchStores();
  };

  const renderStoresPageContent = (
    <Box>
      <CEOInterfaceHeader
        title="Seus estabelecimentos"
        buttonIcon={<AddBusinessIcon />}
        buttonLabel="Adicionar"
        onClick={() => {
          navigate("/stores/add");
        }}
      />

      <Box width="100%">
        {managerStoresContext !== null ? (
          <TableContainer sx={styles.storeItemContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Estabelecimento</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {managerStoresContext.stores?.length !== 0 ? (
                  managerStoresContext.stores?.map((store) => (
                    <TableRow
                      key={store.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        sx={styles.storeItemContent}
                        align="left"
                      >
                        <Avatar
                          src={store.imagePreview[0] ?? defaultStoreImage}
                          sx={styles.avatarStyle}
                        />

                        <ListItemText
                          primary={store.name}
                          secondary={store?.CNPJ}
                          primaryTypographyProps={{ fontWeight: 500 }}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Detalhes">
                          <IconButton
                            color="primary"
                            onClick={() => handleSeeStoreDetailsClick(store)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <IconButton
                            color="primary"
                            onClick={() => handleEditStoreClick(store)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <IconButton
                            color="primary"
                            onClick={() => handleDeleteManagerStore(store)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="text.secondary" variant="body2">
                        Ainda não há estabelecimentos cadastrados
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderStoresPageContent} />
    </Box>
  );
};

export default Stores;

const styles = {
  storeItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  storeItemContent: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
