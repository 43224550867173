import { useEffect, useState } from "react";
import IExcursion from "../../services/api/Excursion/IExcursion";
import { fetchExcursion } from "../../utils/fetchEntities";

const useFetchExcursion = (id: string) => {
  const [excursion, setExcursion] = useState<IExcursion | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    fetchExcursion(id)
      .then((response) => setExcursion(response))
      .finally(() => setLoading(false));
  }, [id]);

  return { excursion, loading };
};
export default useFetchExcursion;
