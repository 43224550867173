import { Button, Icon, IconButton } from "@mui/material";
import { handleSelectOriginalAndPreviewImage } from "../../../utils/imageFunctions";
import { ReactNode } from "react";

interface AddImageInputProps {
  label: string;
  icon?: ReactNode;
  setImages: React.Dispatch<React.SetStateAction<HTMLCanvasElement[]>>;
  originalImages: HTMLCanvasElement[];
  previewImages: HTMLCanvasElement[];
}

const AddImageInput = (props: AddImageInputProps) => {
  const { label, icon, setImages, originalImages, previewImages } = props;

  return (
    <label htmlFor="image-input">
      <Button
        component="span"
        style={{ position: "relative", textTransform: "none" }}
      >
        <input
          type="file"
          accept="image/*"
          multiple
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }}
          onChange={(e) =>
            handleSelectOriginalAndPreviewImage(
              e,
              setImages,
              originalImages,
              previewImages
            )
          }
        />
        {icon ? (
          <IconButton disableRipple>
            <Icon color="primary">{icon}</Icon>
          </IconButton>
        ) : null}

        {label}
      </Button>
    </label>
  );
};

export default AddImageInput;
