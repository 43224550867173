import { Box, SxProps, Typography } from "@mui/material";
import Logo from "../../../components/general/Logo";
import { DEMANDORIA_SUBDOMAINS } from "../../../utils/stringUtils";
import { generalDarkGrey } from "../../../styles/colors";
import BeautyIcon from "../../../assets/img/beauty-card.png";
import PetIcon from "../../../assets/img/pet-card.png";
import TurismIcon from "../../../assets/img/turism-card.png";
import Footer from "../../../components/general/Footer";
import { lazy } from "react";
const NicheCard = lazy(() => import("../../../components/general/NicheCard"));

const GeneralLandingPage = () => {
  const checkIfIsInSubdomain = () =>
    window.location.host.includes(DEMANDORIA_SUBDOMAINS[0]) ||
    window.location.host.includes(DEMANDORIA_SUBDOMAINS[1]) ||
    window.location.host.includes(DEMANDORIA_SUBDOMAINS[2]);

  const handleNicheClick = (nicheName: string) => {
    const domain = `http://${nicheName.toLowerCase()}.${window.location.host}`;

    window.location.href = checkIfIsInSubdomain()
      ? `${domain.split(".")[1]}/`
      : `${domain}/`;
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.headerContainer}>
          <Logo />
          <Typography variant="h6" color={generalDarkGrey}>
            Selecione o nicho desejado
          </Typography>
        </Box>

        <Box sx={styles.cardsContainer}>
          <NicheCard
            onClickAction={() => handleNicheClick("beleza")}
            image={BeautyIcon}
            nicheTitle="Beleza"
          />
          <NicheCard
            onClickAction={() => handleNicheClick("pet")}
            image={PetIcon}
            nicheTitle="Pet"
          />
          <NicheCard
            onClickAction={() => handleNicheClick("turismo")}
            image={TurismIcon}
            nicheTitle="Turismo"
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default GeneralLandingPage;

const styles = {
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
    margin: "150px 0px",
  } as SxProps,

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as SxProps,

  cardsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "30px",

    "@media(max-width:900px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  } as SxProps,
};
