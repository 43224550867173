import axios from "axios";
import { BrazilianAddress } from "../types/types";
import { MutableRefObject, Ref } from "react";

const BRASIL_API_CEP_URL = `https://brasilapi.com.br/api/cep/v1`;

export const formatCEP = (cep: string) => {
  return cep.replace(/\D/g, "");
};

export const fetchAddressByCEP = async (
  cep: string
): Promise<BrazilianAddress> | null => {
  let fetchedAddress: BrazilianAddress | null = null;

  try {
    const addressResponse = await axios.get(`${BRASIL_API_CEP_URL}/${cep}`);

    const foundedAddress: BrazilianAddress = {
      street: addressResponse.data.street,
      city: addressResponse.data.city,
      neighborhood: addressResponse.data.neighborhood,
      state: addressResponse.data.state,
    };

    fetchedAddress = foundedAddress;
  } catch (error) {
    console.warn("Error fetching address from CEP: ", error);
  }

  return fetchedAddress;
};

export const cleanAddressRefs = (
  streetRef: MutableRefObject<HTMLInputElement>,
  districtRef: MutableRefObject<HTMLInputElement>,
  cityRef: MutableRefObject<HTMLInputElement>,
  stateRef: MutableRefObject<HTMLInputElement>
) => {
  streetRef.current.value = "";
  districtRef.current.value = "";
  cityRef.current.value = "";
  stateRef.current.value = "";
};

export const checkIfCepInputWasFilledAfterFetching = (
  wasCepFetched: boolean,
  inputRef: MutableRefObject<HTMLInputElement>
): boolean => {
  return wasCepFetched && inputRef.current.value ? true : false;
};
