import { Avatar, Box, Rating, SxProps, Typography } from "@mui/material";
import { IRating } from "../../../services/api/Rating/types";

interface ServiceRatingListItemProps {
  rating: IRating;
}

const ServiceRatingListItem = (props: ServiceRatingListItemProps) => {
  const { rating } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.mainInfo}>
        <Avatar src={rating.User.imagePreview} />
        <Box>
          <Typography>{rating.User.name}</Typography>
          <Rating value={rating.rating} readOnly />
        </Box>
      </Box>
      <Box>
        <Typography>{rating.comment}</Typography>
      </Box>
    </Box>
  );
};

export default ServiceRatingListItem;

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  } as SxProps,

  mainInfo: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  } as SxProps,
};
