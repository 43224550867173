import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IServiceStore } from "../../services/api/ServiceStore/types";
import serviceStoreApi from "../../services/api/ServiceStore";

const useSearchServiceStores = (search: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [serviceStores, setServiceStores] = useState<IServiceStore[]>([]);
  const [serviceStoresLoading, setServiceStoresLoading] =
    useState<boolean>(false);

  const handleSearchServiceStores = async () => {
    setServiceStoresLoading(true);
    try {
      const searchResponse =
        await serviceStoreApi.getServiceStoresFromAServiceName(search);
      setServiceStores(searchResponse);
    } catch (error) {
      console.warn("Error searching service-stores: ", error);
    }
    setServiceStoresLoading(false);
  };

  const executeSearch = () => {
    setSearchParams({ service: search });

    switch (search.length) {
      case 0:
        setSearchParams({});
        setServiceStores([]);
        break;
      default:
        handleSearchServiceStores();
        break;
    }
  };

  useEffect(() => {
    executeSearch();
  }, [search]);

  return { serviceStores, serviceStoresLoading };
};

export default useSearchServiceStores;
