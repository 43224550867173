import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const sharedStyles = {
  sharedMainContainer: {
    overflowX: "hidden",
    height: "100vh",
    pb: "40px",
  } as SxProps,

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  } as CSSProperties,

  singleFormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  } as SxProps,

  dividedFormContainer: {
    display: "flex",
    gap: "20px",

    "@media(max-width:800px)": { flexDirection: "column" },
  } as SxProps,
};
