import {
  Avatar,
  Box,
  CircularProgress,
  SxProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomCard from "../../../../components/general/CustomCard";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceApi from "../../../../services/api/Service";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { generalDarkGrey } from "../../../../styles/colors";
import { priceFormatter } from "../../../../utils/priceFormatter";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import defaultServiceImage from "../../../../assets/svg/defaultService.svg";
import { IUser } from "../../../../services/api/User/types";
import {
  fetchEmployee,
  fetchEmployeeUser,
} from "../../../../utils/fetchEntities";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";

const CURRENT_DAY_INDEX = new Date().getDay();

const EmployeePage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [employeeUser, setEmployeeUser] = useState<IUser | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(
    null
  );

  const [serviceStores, setServiceStores] = useState<IServiceStore[]>([]);
  const [serviceNames, setServiceNames] = useState<string[]>([]);

  const [employeeServicesLoading, setEmployeeServicesLoading] = useState(false);

  const fetchServiceStoresFromEmployee = async (): Promise<void> => {
    setEmployeeServicesLoading(true);
    try {
      const employeeServiceEmployeesResponse =
        await serviceEmployeeApi.getServiceEmployeesFromEmployeeId(
          selectedEmployee.id
        );

      const serviceStoresAuxiliarArray: IServiceStore[] = [];
      const serviceNamesAuxiliarArray: string[] = [];

      let iterableServiceName: string = "";
      for (
        let index = 0;
        index < employeeServiceEmployeesResponse.length;
        index++
      ) {
        serviceStoresAuxiliarArray.push(
          employeeServiceEmployeesResponse[index].ServiceStore
        );
        iterableServiceName = (
          await serviceApi.getService(
            employeeServiceEmployeesResponse[index].ServiceStore.serviceId
          )
        ).name;
        serviceNamesAuxiliarArray.push(iterableServiceName);
      }

      setServiceStores(serviceStoresAuxiliarArray);
      setServiceNames(serviceNamesAuxiliarArray);
      setEmployeeServicesLoading(false);
    } catch (error) {
      console.warn("Error fetching service-stores from employee: ", error);
    }
    setEmployeeServicesLoading(false);
  };

  useEffect(() => {
    fetchServiceStoresFromEmployee();
  }, [selectedEmployee]);

  const handleSelectServiceClick = async (
    storeId: string,
    serviceName: string
  ) => {
    try {
      const res =
        await serviceStoreApi.getServiceStoresFromServiceNameAndStoreId(
          serviceName,
          storeId
        );
      navigate(`/service?id=${res[0].id}&employeeId=${selectedEmployee.id}`);
    } catch (error) {
      console.warn("Error getting service-store: ", error);
    }
  };

  useEffect(() => {
    fetchEmployeeUser(selectedEmployee?.userId!, setEmployeeUser!);
  }, [selectedEmployee]);

  useEffect(() => {
    fetchEmployee(searchParams.get("id")!, setSelectedEmployee!);
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Box width="100vw" margin="40px 0px" sx={styles.flexContainer}>
        <>
          <Box sx={styles.flexContainer} gap={2}>
            <Avatar
              src={employeeUser?.imageOriginal}
              sx={{
                width: 150,
                height: 150,
                border: "4px solid",
                borderColor: "secondary.main",
              }}
            />
            <Box sx={styles.flexContainer}>
              <Typography variant="h5" fontWeight="bold">
                {employeeUser?.name || "Carregando..."}
              </Typography>
            </Box>
          </Box>

          <Box sx={styles.flexContainer} gap={2} margin="30px 0px">
            <Typography fontWeight="bold" variant="body1" textAlign="center">
              Contatos:
            </Typography>
            <Box sx={styles.flexContainer} gap={1}>
              <Box display="flex" gap={1}>
                <LocalPhoneIcon color="primary" />
                <Typography variant="body1">
                  {employeeUser?.phone || "Carregando..."}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <AlternateEmailIcon color="primary" />
                <Typography variant="body1">
                  {employeeUser?.email || "Carregando..."}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography variant="h5" fontWeight="bold" margin="20px 0px">
            Serviços deste funcionário
          </Typography>
          <Box
            display="flex"
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              width: "90%",
              p: "10px",
              mb: "20px",
            }}
            gap={2}
          >
            {serviceStores.length > 0 ? (
              serviceStores.map((serviceStore: IServiceStore, index) => (
                <Box
                  key={serviceStore.id}
                  onClick={() => {
                    handleSelectServiceClick(
                      serviceStore.storeId,
                      serviceNames[index]
                    );
                  }}
                >
                  <CustomCard
                    photoURL={serviceStore.imagePreview[0]}
                    defaultPhoto={defaultServiceImage}
                    header={`R$ ${priceFormatter(
                      serviceStore.priceDay[CURRENT_DAY_INDEX]
                    )}`}
                    primaryText={serviceNames[index]}
                    secondaryText={serviceStore.Store.name}
                  />
                </Box>
              ))
            ) : employeeServicesLoading ? (
              <Box sx={styles.flexContainer} width="100%">
                <CircularProgress sx={{ margin: "50px 0px" }} />
              </Box>
            ) : (
              <Box sx={styles.flexContainer} width="100%">
                <Typography color={generalDarkGrey}>
                  Este funcionário não está associado a nenhum serviço.
                </Typography>
              </Box>
            )}
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default EmployeePage;

const styles = {
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  } as SxProps,
};
