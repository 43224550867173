import { Box, IconButton, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackPageButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => navigate(-1)}
      color="primary"
      sx={{ width: "100%", height: "100%" }}
    >
      <ArrowBackIcon sx={{ width: "100%", height: "100%" }} />
    </IconButton>
  );
};

export default BackPageButton;
