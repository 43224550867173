import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState, useEffect, lazy } from "react";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import appointmentApi from "../../../../services/api/Appointment";
import { IAppointment } from "../../../../services/api/Appointment/types";
import { formatDateAndTimeDayDateToString } from "../ScheduilingPage/timeUtils";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { AppointmentStatus } from "../../../../types/types";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
const AppointmentsCalendar = lazy(
  () => import("../../../../components/general/AppointmentsCalendar")
);

const ClientAppointments = () => {
  const auth = useAuth();
  const today = dayjs();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [areAppointmentsLoading, setAreAppointmentsLoading] = useState(true);

  const [succeededRatingPost, setSucceededRatingPost] = useState(false);

  const [userAppointments, setUserAppointments] = useState([]);

  const [selectedUserAppointment, setSelectedUserAppointment] = useState(null);

  const [pendentAppointments, setPendentAppointments] = useState([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [canceledAppointments, setCanceledAppointments] = useState([]);
  const [finishedAppointments, setFinishedAppointments] = useState([]);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const handleOpenModal = (selectedAppointment: IAppointment) => {
    setSelectedUserAppointment(selectedAppointment);
    setIsAppointmentModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsAppointmentModalOpen(false);
  };

  const fetchUsersAppointments = async () => {
    try {
      const userAppointmentsResponse =
        await appointmentApi.getAppointmentsFromClient(
          auth.user.id,
          new Date(today.toISOString())
        );

      setUserAppointments(userAppointmentsResponse);
      getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments(
        userAppointmentsResponse
      );
    } catch (error) {
      console.warn("Error fetching user appointments: ", error);
    }
    setAreAppointmentsLoading(false);
  };

  const getPendentConfirmedCanceledAndFinishedAppointmentDatesFromUserAppointments =
    (appointments: IAppointment[]) => {
      const auxiliarPendentAppointmentDays = [];
      const auxiliarConfirmedAppointmentDays = [];
      const auxiliarCanceledAppointmentDays = [];
      const auxiliarFinishedAppointmentDays = [];

      let iterableAppointment = null;
      let iterableAppointmentDate = "";
      const highlightedDaysArray = [];

      for (let i = 0; i < appointments.length; i++) {
        iterableAppointment = appointments[i];
        iterableAppointmentDate = iterableAppointment.date.split("T")[0];

        highlightedDaysArray.push(iterableAppointmentDate);

        if (
          iterableAppointment.status === AppointmentStatus.PENDENT &&
          !auxiliarPendentAppointmentDays.includes(iterableAppointmentDate)
        )
          auxiliarPendentAppointmentDays.push(iterableAppointmentDate);

        if (
          iterableAppointment.status === AppointmentStatus.CONFIRMED &&
          !auxiliarConfirmedAppointmentDays.includes(iterableAppointmentDate)
        )
          auxiliarConfirmedAppointmentDays.push(iterableAppointmentDate);

        if (
          iterableAppointment.status === AppointmentStatus.CANCELED &&
          !auxiliarCanceledAppointmentDays.includes(iterableAppointmentDate)
        )
          auxiliarCanceledAppointmentDays.push(iterableAppointmentDate);

        if (
          iterableAppointment.status === AppointmentStatus.FINISHED &&
          !auxiliarFinishedAppointmentDays.includes(iterableAppointmentDate)
        )
          auxiliarFinishedAppointmentDays.push(iterableAppointmentDate);
      }

      setPendentAppointments(auxiliarPendentAppointmentDays);
      setConfirmedAppointments(auxiliarConfirmedAppointmentDays);
      setCanceledAppointments(auxiliarCanceledAppointmentDays);
      setFinishedAppointments(auxiliarFinishedAppointmentDays);
    };

  useEffect(() => {
    if (auth.user.id) fetchUsersAppointments();
  }, [auth]);

  const handleFinishService = () => {
    fetchUsersAppointments();
    handleCloseModal();
  };

  const AppointmentsModal = (
    <>
      <AppointmentDetailsModal
        open={isAppointmentModalOpen}
        appointment={selectedUserAppointment}
        onClose={handleCloseModal}
        onFinish={handleFinishService}
        setSucceededRatingPost={setSucceededRatingPost}
      />
      <Snackbar
        open={succeededRatingPost}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSucceededRatingPost(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Serviço avaliado com sucesso!
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      {AppointmentsModal}
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Box sx={styles.mainContainer}>
        {!areAppointmentsLoading ? (
          <>
            <Typography variant="h5" fontWeight="bold">
              Seus agendamentos
            </Typography>

            <Box sx={styles.appointmentsBoard}>
              <AppointmentsCalendar
                pendentAppointmentDates={pendentAppointments}
                confirmedAppointmentDates={confirmedAppointments}
                finishedAppointementDates={finishedAppointments}
                canceledAppointmentDates={canceledAppointments}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />

              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography variant="h6">
                  Dia {selectedDate?.format("DD/MM/YYYY")}
                </Typography>

                {userAppointments.some(
                  (appointment) =>
                    appointment.date.split("T")[0] ===
                    selectedDate?.format("YYYY-MM-DD")
                ) ? (
                  <List
                    sx={{
                      width: "500px",
                      height: "405px",
                      overflowY: "auto",
                      boxShadow:
                        "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.15), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.15)",
                      "@media (max-width: 900px)": {
                        width: "100%",
                      },
                      borderRadius: "10px",
                    }}
                    disablePadding
                  >
                    {userAppointments.map((appointment, index) =>
                      appointment.date.split("T")[0] ===
                      selectedDate?.format("YYYY-MM-DD") ? (
                        <ListItemButton
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                          }}
                          onClick={() =>
                            handleOpenModal(userAppointments[index])
                          }
                          key={index}
                        >
                          <ListItemText
                            primary={`${userAppointments[index].serviceEmployee.ServiceStore.Service.name} - ${userAppointments[index].serviceEmployee.Employee.store.name}`}
                            secondary={`${formatDateAndTimeDayDateToString(
                              userAppointments[index].date
                            )} com ${
                              userAppointments[index].serviceEmployee.Employee
                                .User.name
                            } `}
                            secondaryTypographyProps={{}}
                          />
                          <AppointmentStatusChip
                            status={userAppointments[index].status}
                          />
                        </ListItemButton>
                      ) : (
                        <></>
                      )
                    )}
                  </List>
                ) : (
                  <Box width="500px">
                    <Typography color="text.secondary">
                      Nenhum agendamento para esta data.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={{ marginTop: "100px" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ClientAppointments;

const styles = {
  backButton: {
    margin: "5px",
  },

  backIcon: {
    width: "30px",
    height: "30px",
  },

  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    margin: "0px 30px 80px 30px",
  } as SxProps,

  appointmentsBoard: {
    display: "flex",
    justifyContent: "center",
    gap: "40px",

    "@media(max-width:1000px)": {
      flexDirection: "column",
    },
  } as SxProps,
};
