import { Box, SxProps, TextField, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import { useRef, useState } from "react";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

const CartPayment = () => {
  const auth = useAuth();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const docNumberRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(auth.user.CEP);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const paymentMethodRef = useRef<HTMLSelectElement>(null);

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      defaultValue={auth.user.docNumber}
      inputRef={docNumberRef}
    >
      {<TextField label="CPF*" sx={{ width: "200px" }} />}
    </InputMask>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />

      <Box sx={styles.container}>
        <Typography variant="h5" fontWeight="bold">
          Serviço agendado com sucesso!
        </Typography>
        <Typography>
          O pagamento deve ser realizado presencialmente no estabelecimento.
        </Typography>
      </Box>
    </Box>
  );
};

export default CartPayment;

const styles = {
  container: {
    width: "100%",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "10px",
  } as SxProps,
};
