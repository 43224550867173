import { LoadingButton } from "@mui/lab";
import { SxProps } from "@mui/material";
import { ReactNode } from "react";

interface PrimaryButtonProps {
  label: string;
  onClickAction?: any;
  isLoading?: boolean;
  icon?: ReactNode;
  isDisabled?: boolean;
  styles?: SxProps;
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const { label, onClickAction, isLoading, icon, isDisabled, styles } = props;

  return (
    <LoadingButton
      variant="contained"
      disableElevation
      sx={{ ...styles, textTransform: "none", height: "50px" }}
      startIcon={icon}
      loading={isLoading}
      loadingPosition={icon ? "start" : undefined}
      type="submit"
      fullWidth
      onClick={onClickAction}
      disabled={isDisabled}
    >
      {label}
    </LoadingButton>
  );
};
