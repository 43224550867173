import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { lazy, useEffect, useState } from "react";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { userApi } from "../../../../services";
import employeeApi from "../../../../services/api/Employee";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import { IServiceEmployee } from "../../../../services/api/ServiceEmployee/types";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IUser } from "../../../../services/api/User/types";
import { priceFormatter } from "../../../../utils/priceFormatter";
import DescriptionWrapper from "../../../../components/general/DescriptionWrapper";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { IRating } from "../../../../services/api/Rating/types";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { IStore } from "../../../../services/api/Store/types";
import {
  fetchServiceStore,
  fetchServiceStoreRating,
  fetchStore,
} from "../../../../utils/fetchEntities";
import ServiceRatingList from "../../../../components/pet-beauty/ServiceRatingList";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
const ImageCarousel = lazy(
  () => import("../../../../components/general/ImageCarousel")
);

const ServicePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEmployeeIdParam = searchParams.get("employeeId");

  const serviceId = searchParams.get("id");

  const [selectedServiceStore, setSelectedServiceStore] =
    useState<IServiceStore | null>(null);
  const [store, setStore] = useState<IStore | null>(null);

  const selectedDateParam = searchParams.get("selectedDate");

  const CURRENT_DAY_INDEX = new Date().getDay();

  const [serviceEmployeeUsers, setServiceEmployeeUsers] = useState<IUser[]>([]);
  const [serviceEmployees, setServiceEmployees] = useState<IEmployee[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    defaultEmployeeIdParam || ""
  );
  const [ratings, setRatings] = useState<IRating[]>([]);

  const [areEmployeesLoading, setAreEmployeesLoading] = useState(true);
  const [serviceLoading, setServiceLoading] = useState(true);

  const [selectedEmployeeError, setSelectedEmployeeError] = useState(false);

  const handleStoreClick = () =>
    navigate(`/store?id=${selectedServiceStore?.storeId}`);

  const fetchServiceEmployees = async () => {
    try {
      const serviceEmployeeRes: IServiceEmployee[] =
        await serviceEmployeeApi.getServiceEmployeeByServiceStoreId(serviceId);

      let iterableUser = null;
      const updatedServiceEmployeeUsers = [];
      const updatedServiceEmployees = [];

      for (let i = 0; i < serviceEmployeeRes.length; i++) {
        updatedServiceEmployees.push(serviceEmployeeRes[i].Employee);

        iterableUser = await userApi.getUser(
          serviceEmployeeRes[i].Employee.userId
        );

        updatedServiceEmployeeUsers.push(iterableUser);
      }

      setServiceEmployees(updatedServiceEmployees);
      setServiceEmployeeUsers(updatedServiceEmployeeUsers);

      if (!defaultEmployeeIdParam && updatedServiceEmployees.length > 0)
        setSelectedEmployeeId(updatedServiceEmployees[0].id);
    } catch (error) {
      console.warn("Error fetching service-employee: ", error);
    }

    setAreEmployeesLoading(false);
  };

  const handleScheduleServiceClick = async () => {
    if (selectedEmployeeId.length > 0) {
      setSelectedEmployeeError(false);
      try {
        const employeeRes = await employeeApi.getEmployee(selectedEmployeeId);
        const employeeUserRes = await userApi.getUser(employeeRes.userId);

        localStorage.setItem(
          "selectedEmployeeName",
          JSON.stringify(employeeUserRes.name)
        );
        selectedDateParam
          ? navigate(
              `/scheduiling?serviceId=${selectedServiceStore?.id}&employee=${employeeRes.id}&selectedDate=${selectedDateParam}`
            )
          : navigate(
              `/scheduiling?serviceId=${selectedServiceStore?.id}&employee=${employeeRes.id}`
            );
      } catch (error) {
        console.warn(error);
      }
    } else setSelectedEmployeeError(true);
  };

  useEffect(() => {
    const fetchStoreData = async () => {
      await Promise.all([
        fetchServiceStoreRating(serviceId!, setRatings!),
        fetchServiceEmployees(),
        fetchServiceStore(serviceId!, setSelectedServiceStore!),
      ]);
    };

    fetchStoreData();
  }, []);

  useEffect(() => {
    if (selectedServiceStore?.storeId)
      fetchStore(selectedServiceStore?.storeId!, setStore!);

    setServiceLoading(false);
  }, [selectedServiceStore]);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />
      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box sx={styles.mainContainer}>
        {!serviceLoading && selectedServiceStore ? (
          <>
            <Box sx={styles.serviceContainer}>
              {selectedServiceStore?.imageOriginal!.length > 0 ? (
                <Box sx={styles.imageContainer}>
                  <ImageCarousel
                    imageUrls={selectedServiceStore?.imageOriginal!}
                  />
                </Box>
              ) : (
                <></>
              )}

              <Box sx={styles.serviceDetails}>
                <Box sx={{ ...styles.serviceDetails, gap: "20px" }}>
                  <Typography variant="h5" fontWeight="bold">
                    {selectedServiceStore?.Service?.name!}
                  </Typography>
                  <Typography>
                    <DescriptionWrapper
                      text={selectedServiceStore?.description!}
                      max={200}
                    />
                  </Typography>
                  <Typography variant="h5" color="primary" fontWeight="bold">
                    {`R$ ${priceFormatter(
                      selectedServiceStore?.priceDay[CURRENT_DAY_INDEX]!
                    )}`}
                  </Typography>
                </Box>
                {!areEmployeesLoading ? (
                  <FormControl size="small">
                    <InputLabel>Selecione o profissional</InputLabel>
                    <Select
                      label="Selecione o profissional"
                      value={selectedEmployeeId}
                      onChange={(e) => {
                        setSelectedEmployeeId(e.target.value);
                      }}
                      defaultValue={
                        defaultEmployeeIdParam! ?? selectedEmployeeId
                      }
                      error={!!selectedEmployeeError}
                    >
                      {serviceEmployeeUsers
                        .filter((user) => user.id !== auth.user.id)
                        .map((employeeUser: IUser, index) => (
                          <MenuItem
                            key={employeeUser.id}
                            value={serviceEmployees[index].id}
                          >
                            <ListItem disablePadding>
                              <ListItemAvatar>
                                <Avatar
                                  src={employeeUser.imagePreview}
                                  imgProps={{ loading: "lazy" }}
                                />
                              </ListItemAvatar>
                              <ListItemText primary={employeeUser.name} />
                            </ListItem>
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedEmployeeError ? (
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        É necessário selecionar um profissional para o serviço.
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                ) : (
                  <Box>
                    <CircularProgress />
                  </Box>
                )}

                <PrimaryButton
                  label="Agendar serviço"
                  icon={<EventNoteIcon />}
                  onClickAction={handleScheduleServiceClick}
                />
              </Box>
            </Box>

            <Box sx={styles.storeContainer}>
              <Typography>Serviço oferecido por: </Typography>

              <Box onClick={handleStoreClick} sx={styles.storeItem}>
                <Avatar src={store?.imagePreview[0]} sx={styles.storeAvatar} />
                <ListItemText
                  primary={store?.name}
                  secondary={store?.listTags}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </Box>
            </Box>

            <Divider sx={{ width: "100%" }} />

            <Box sx={styles.ratingsContainer}>
              <Typography variant="h5" fontWeight="bold">
                Avaliações dos usuários
              </Typography>

              <ServiceRatingList ratings={ratings} />
            </Box>
          </>
        ) : (
          <Box sx={{ marginTop: "100px" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServicePage;

const styles = {
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "60px",
    margin: "10px 0px",
    padding: "0px 30px",
  } as SxProps,

  serviceContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    gap: "50px",
    justifyContent: "center",

    "@media(max-width: 900px)": {
      flexDirection: "column",
    },
  } as SxProps,

  imageContainer: {
    width: "50%",
    height: "500px",

    "@media(max-width: 900px)": {
      width: "100%",
      height: "300px",
    },
  } as SxProps,

  serviceDetails: {
    maxWidth: "400px",
    textAlign: "justify",
    display: "flex",
    flexDirection: "column",
    gap: "40px",

    "@media(max-width: 900px)": {
      maxWidth: "100%",
    },
  } as SxProps,

  storeAvatar: {
    width: "70px",
    height: "70px",
  } as SxProps,

  storeItem: {
    display: "flex",
    gap: "20px",
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  } as SxProps,

  storeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  } as SxProps,

  ratingsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "50px",
  } as SxProps,
};
