import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { lazy, useEffect, useState } from "react";
import { ISO_SERVICE_TIMESTAMPS, SERVICE_TIMESTAMPS } from "./timeUtils";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
const AvailabilityCard = lazy(
  () => import("../../../../components/general/AvailabilityCard")
);
const ScheduilingModal = lazy(
  () => import("../../../../components/general/ScheduilingModal")
);
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import appointmentApi from "../../../../services/api/Appointment";
import serviceEmployeeApi from "../../../../services/api/ServiceEmployee";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IAppointment } from "../../../../services/api/Appointment/types";
import BackPageButton from "../../../../components/general/buttons/BackPageButton";
import { fetchServiceStore } from "../../../../utils/fetchEntities";
import { backButtonContainer } from "../../../../styles/sharedComponentStyles";
import { shortenLargeTexts } from "../../../../utils/stringUtils";

const ScheduilingPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const today = dayjs();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDate = searchParams.get("selectedDate");
  const [areTimesLoading, setAreTimeLoading] = useState(true);

  const employeeId = searchParams.get("employee");
  const serviceId = searchParams.get("serviceId");

  const [selectedServiceStore, setSelectedServiceStore] =
    useState<IServiceStore | null>(null);

  const selectedEmployeeName = JSON.parse(
    localStorage.getItem("selectedEmployeeName")
  );

  const [date, setDate] = useState(
    selectedDate ? dayjs.utc(selectedDate) : today
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedTimeDay, setSelectedTimeDay] = useState<string>("");
  const [selectedTimeAndDate, setSelectedTimeAndDate] = useState<string>("");

  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  const getServiceEmployeeId = async () => {
    const serviceEmployeeResponse =
      await serviceEmployeeApi.getServiceEmployeeByServiceStoreIdAndEmployeeId(
        serviceId,
        employeeId
      );

    const { id } = serviceEmployeeResponse[0];

    return id;
  };

  const fetchAvailableAppointmentTimes = async () => {
    setAreTimeLoading(true);
    const serviceEmployeeId = await getServiceEmployeeId();

    try {
      const res = await appointmentApi.getServiceEmployeeAvailableTimesInADate(
        date.toISOString(),
        serviceEmployeeId
      );

      const temporaryAvailableTimesArray: string[] = [];

      for (let index = 0; index < res.length; index++) {
        if (res[index].available)
          temporaryAvailableTimesArray.push(res[index].time);
      }
      setAvailableTimes(temporaryAvailableTimesArray);
    } catch (error) {
      console.warn("Error fetching available appointment times: ", error);
    }
    setAreTimeLoading(false);
  };

  const handleSubmitAppointment = async () => {
    const serviceEmployeeId = await getServiceEmployeeId();

    try {
      const appointmentResponse = await appointmentApi.createAppointment({
        serviceEmployeeId,
        clientId: auth.user.id,
        date: selectedTimeAndDate,
      } as IAppointment);

      console.log("Appointment successfully created: ", appointmentResponse);
      handleCloseModal();
      alert(
        `Serviço agendado com sucesso para ${date.format(
          "DD/MM/YYYY"
        )} às ${selectedTimeDay} horas.`
      );

      navigate("/cart");
    } catch (error) {
      console.warn("Error scheduiling a service: ", error);

      confirm(
        "Conclua o carrinho em aberto antes de abrir um novo carrinho.\n\nOBS.: Só é possível preencher o carrinho com serviços do mesmo estabelecimento."
      )
        ? navigate("/cart")
        : null;

      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchAvailableAppointmentTimes();
  }, [date]);

  useEffect(() => {
    fetchServiceStore(serviceId!, setSelectedServiceStore!);
  }, []);

  const handleOpenModal = (time: string) => {
    setSelectedTimeDay(time);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ScheduilingModal
        open={isModalOpen}
        onClose={handleCloseModal}
        price={selectedServiceStore?.priceDay[date?.toDate().getDay()]}
        date={date?.format("DD/MM/YYYY")}
        timeSelected={selectedTimeDay}
        onConfirm={handleSubmitAppointment}
      />
      <ClientNavbar hideMidButton />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
      <Box sx={styles.container}>
        {!areTimesLoading ? (
          <>
            <Box sx={styles.header}>
              <ListItem disablePadding>
                <ListItemAvatar>
                  <Avatar src={selectedServiceStore?.imagePreview[0]} />
                </ListItemAvatar>
                <ListItemText
                  primary={shortenLargeTexts(
                    selectedServiceStore?.Service?.name,
                    37
                  )}
                  secondary={shortenLargeTexts(
                    `Funcionário: ${selectedEmployeeName}`,
                    45
                  )}
                  primaryTypographyProps={{ fontWeight: 500 }}
                  secondaryTypographyProps={{ color: "text.primary" }}
                />
              </ListItem>

              <Box sx={styles.appointmentDateInput}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <DatePicker
                    label="Data de agendamento"
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    value={date}
                    onChange={(newScheduilingDate) => {
                      setDate(newScheduilingDate);
                    }}
                    disablePast
                    dayOfWeekFormatter={(day: string) => day}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Divider sx={{ width: "100%" }} />

            <Box sx={styles.appointmentsCardGrid}>
              {SERVICE_TIMESTAMPS.map((time, index) => (
                <Box key={index}>
                  <AvailabilityCard
                    time={time}
                    function={() => {
                      handleOpenModal(time);
                      setSelectedTimeAndDate(
                        `${date.toISOString().split("T")[0]}${
                          ISO_SERVICE_TIMESTAMPS[index]
                        }`
                      );
                    }}
                    isAvailable={availableTimes.includes(
                      SERVICE_TIMESTAMPS[index]
                    )}
                  />
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Box sx={{ marginTop: "100px" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScheduilingPage;

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    margin: "10px 30px 50px 30px",
  } as SxProps,

  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "20px",

    "@media(max-width:700px)": {
      flexDirection: "column",
    },
  } as SxProps,

  appointmentDateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  } as SxProps,

  appointmentDateInput: {
    width: "400px",
    "@media(max-width:700px)": {
      width: "100%",
    },
  } as SxProps,

  appointmentsCardGrid: {
    display: "grid",
    maxWidth: "100%",
    alignItems: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(170px, 1fr))",
    columnGap: 2,
    rowGap: 2,
  } as SxProps,
};
