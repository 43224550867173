import { useEffect, useState } from "react";
import IExcursion from "../../services/api/Excursion/IExcursion";
import { useSearchParams } from "react-router-dom";
import excursionApi from "../../services/api/Excursion";

const useSearchExcursions = (search: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [excursions, setExcursions] = useState<IExcursion[]>([]);
  const [excursionsLoading, setExcursionsLoading] = useState<boolean>(false);

  const handleSearchExcursions = async () => {
    setExcursionsLoading(true);
    try {
      const searchResponse = await excursionApi.searchExcursions(search);
      setExcursions(searchResponse);
    } catch (error) {
      console.warn("Error searching excursions: ", error);
    }
    setExcursionsLoading(false);
  };

  const executeSearch = () => {
    setSearchParams({ store: search });

    switch (search.length) {
      case 0:
        setSearchParams({});
        setExcursions([]);
        break;
      default:
        handleSearchExcursions();
        break;
    }
  };

  useEffect(() => {
    executeSearch();
  }, [search]);

  return { excursions, excursionsLoading };
};

export default useSearchExcursions;
