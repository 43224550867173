import { Badge } from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import {
  dmYellow,
  generalBrightGrey,
  generalDarkGrey,
  invalidAvailabilityBorder,
  validAvailability,
  validAvailabilityBorder,
} from "../../../styles/colors";
import { Dispatch, SetStateAction } from "react";

interface AppointmentsCalendarProps {
  pendentAppointmentDates: string[];
  confirmedAppointmentDates: string[];
  canceledAppointmentDates: string[];
  finishedAppointementDates: string[];
  selectedDate: Dayjs;
  setSelectedDate: Dispatch<SetStateAction<Dayjs>>;
}

const today = dayjs();

const AppointmentsCalendar = (props: AppointmentsCalendarProps) => {
  const {
    pendentAppointmentDates,
    confirmedAppointmentDates,
    canceledAppointmentDates,
    finishedAppointementDates,
    selectedDate,
    setSelectedDate,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DateCalendar
        defaultValue={today}
        slots={{
          day: (props) => {
            const iterableDate: string = props.day.format("YYYY-MM-DD");

            const isPending = pendentAppointmentDates.includes(iterableDate);
            const isConfirmed =
              confirmedAppointmentDates.includes(iterableDate);
            const isCancelled = canceledAppointmentDates.includes(iterableDate);
            const isFinished = finishedAppointementDates.includes(iterableDate);

            let badgeColor;

            if (isConfirmed) badgeColor = validAvailability;
            else if (isPending) badgeColor = dmYellow;
            else if (isCancelled) badgeColor = invalidAvailabilityBorder;
            else if (isFinished) badgeColor = generalDarkGrey;

            return (
              <Badge
                key={props.day.toString()}
                overlap="circular"
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: badgeColor,
                  },
                }}
              >
                <PickersDay {...props} day={props.day} />
              </Badge>
            );
          },
        }}
        dayOfWeekFormatter={() => {}}
        value={selectedDate}
        onChange={(newDate) => {
          setSelectedDate(newDate);
        }}
        showDaysOutsideCurrentMonth
        sx={styles}
      />
    </LocalizationProvider>
  );
};

export default AppointmentsCalendar;

const styles = {
  borderRadius: "10px",
  border: `1px solid ${generalBrightGrey}`,
  minWidth: "55%",
  minHeight: "450px",

  "@media (max-width: 1000px)": {
    minWidth: "100%",
  },

  "& .MuiPickersDay-dayWithMargin": {
    width: "40px",
    height: "40px",
    fontSize: "12pt",
  },

  "& .MuiDayCalendar-header": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 20px",
    margin: "5px 0px 15px 0px",
  },

  "& .MuiDayCalendar-weekContainer": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    margin: "5px 0px",
  },

  "& .MuiDayCalendar-slideTransition": {
    minHeight: "500px",
  },
};
