import { NavigateFunction } from "react-router-dom";
import { IContext } from "../context/AuthProvider/types";

export const handleLogout = (auth: IContext, navigate: NavigateFunction) => {
  if (window.confirm("Tem certeza que deseja sair?")) {
    localStorage.removeItem("token");
    auth.logout();
    navigate("/login");
  }
};
