import { Box, CircularProgress, List, SxProps } from "@mui/material";
import { IStore } from "../../../services/api/Store/types";
import { useState } from "react";
import NearbyStoreListItem from "./NearbyStoreListItem";

interface NearbyServicesList {
  stores: IStore[];
  userCoordinates: [number, number];
  mapReference: any;
}

const NearbyStoreList = (props: NearbyServicesList) => {
  const { stores, mapReference, userCoordinates } = props;

  const [selectedStoreIndex, setSelectedStoreIndex] = useState<number | null>(
    null
  );

  const handleListStoreClick = (
    latitude: number,
    longitude: number,
    index: number
  ) => {
    setSelectedStoreIndex(index);
    mapReference.setView([latitude, longitude], 18);
  };

  return (
    <Box sx={styles.container}>
      {stores.length > 0 ? (
        <List sx={styles.list}>
          {stores.map((store: IStore, index: number) => (
            <NearbyStoreListItem
              onClick={() => handleListStoreClick(store.lat, store.lng, index)}
              store={store}
              userCoordinates={userCoordinates}
              isSelected={index === selectedStoreIndex}
              key={store.id}
            />
          ))}
        </List>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default NearbyStoreList;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    scrollBehavior: "smooth",
  } as SxProps,

  list: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "5px",
  } as SxProps,

  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "100px",
  } as SxProps,
};
