import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import { deleteImageFromFirebase } from "../../../../../utils/firebase";

export const handleDeleteStore = async (store: IStore) => {
  if (window.confirm(`Tem certeza que deseja excluir ${store.name}?`)) {
    try {
      if (store.imageOriginal.length > 0) {
        const deleteImagePromises = [];

        for (let i = 0; i < store.imageOriginal.length; i++) {
          deleteImagePromises.push(
            deleteImageFromFirebase(store.imageOriginal[i])
          );
          deleteImagePromises.push(
            deleteImageFromFirebase(store.imagePreview[i])
          );
        }

        await Promise.all(deleteImagePromises);
      }

      await storeApi.deleteStore(store.id);
    } catch (error) {
      console.error("Error deleting the store: ", error);
    }
  }
};
