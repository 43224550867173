import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import "dayjs/locale/pt-br";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useNavigate } from "react-router-dom";
import { lazy, useState } from "react";
import IExcursion from "../../../../services/api/Excursion/IExcursion";
import { priceFormatter } from "../../../../utils/priceFormatter";
import defaultExcursionImage from "../../../../assets/svg/defaultExcursion.svg";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import { IStore } from "../../../../services/api/Store/types";
import useSearchExcursions from "../../../../hooks/search/useSearchExcursions";
import useSearchStores from "../../../../hooks/search/useSearchStores";
import SearchAutocomplete from "../../../../components/general/SearchAutocomplete";
import SearchTabs from "../../../../components/general/SearchTabs";
import tabsInfo from "./tabs";
import { setLocalStorageItem } from "../../../../hooks/localStorageHooks";
const CustomCard = lazy(
  () => import("../../../../components/general/CustomCard")
);

const TourismSearchPage = () => {
  const navigate = useNavigate();

  const [searchTypeIndex, setSearchTypeIndex] = useState<number>(0);

  const [excursionSearchInput, setExcursionSearchInput] = useState<string>("");
  const { excursions, excursionsLoading } =
    useSearchExcursions(excursionSearchInput);

  const [storeSearchInput, setStoreSearchInput] = useState<string>("");
  const { stores, storesLoading } = useSearchStores(storeSearchInput);

  const handleSelectStoreClick = (storeId: string) => {
    setLocalStorageItem("searchType", 1);
    navigate(`/store?id=${storeId}`);
  };

  const handleSelectExcursionClick = (excursionId: string) => {
    setLocalStorageItem("searchType", 0);
    navigate(`/excursion?id=${excursionId}`);
  };

  const renderResultsFoundedTypography = (resultsNumber: number) => {
    return (
      <Typography
        variant="h6"
        fontWeight="bold"
      >{`Resultados encontrados (${resultsNumber}):`}</Typography>
    );
  };

  const excursionSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {excursions.map((excursion: IExcursion, _) => (
        <Box
          onClick={() => {
            handleSelectExcursionClick(excursion.id);
          }}
          key={excursion.id}
        >
          <CustomCard
            photoURL={excursion.imagePreview[0]}
            header={`R$ ${priceFormatter(excursion.price * 100)}`}
            primaryText={excursion.title}
            secondaryText={`${excursion.city} - ${excursion.country}`}
            defaultPhoto={defaultExcursionImage}
          />
        </Box>
      ))}
    </Box>
  );

  const excursionSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={""}
          label="Busque por uma excursão"
          options={excursions.map((excursion) => excursion?.title)}
          value={excursionSearchInput}
          setValue={setExcursionSearchInput}
        />
      </Box>

      {!excursionsLoading ? (
        <>
          {excursions.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              {renderResultsFoundedTypography(excursions.length)}
              {excursionSearchResults}
            </Box>
          ) : excursionSearchInput.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              <Typography variant="h6">Nenhuma excursão encontrada.</Typography>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      )}
    </Box>
  );

  const storeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {stores.map((store: IStore, _) => (
        <Box
          onClick={() => {
            handleSelectStoreClick(store.id);
          }}
          key={store.id}
        >
          <CustomCard
            photoURL={store.imagePreview[0]}
            header={store.name}
            secondaryText={store.description}
            defaultPhoto={defaultStoreImage}
            primaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const storeSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={""}
          label="Busque por uma agência"
          options={stores.map((store) => store.name)}
          value={storeSearchInput}
          setValue={setStoreSearchInput}
        />
      </Box>

      {!storesLoading ? (
        <>
          {stores.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              {renderResultsFoundedTypography(stores.length)}
              {storeSearchResults}
            </Box>
          ) : storeSearchInput.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              <Typography variant="h6">
                Nenhum estabelecimento encontrado.
              </Typography>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      )}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar hideMidButton />

      <SearchTabs
        value={searchTypeIndex}
        setValue={setSearchTypeIndex}
        tabs={tabsInfo}
      />

      <Box
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {searchTypeIndex === 0
          ? excursionSearch
          : searchTypeIndex === 1
            ? storeSearch
            : null}
      </Box>
    </Box>
  );
};

export default TourismSearchPage;

const styles = {
  searchContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
    margin: "40px 0px",
    padding: "0px 20px",
  } as SxProps,

  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as SxProps,

  searchResultsGrid: {
    display: "grid",
    maxWidth: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    margin: "20px 0px",
    rowGap: 3,
    columnGap: 3,
  } as SxProps,

  autoCompleteContainer: { maxWidth: "450px", width: "100%" } as SxProps,

  searchLoadingContainer: { margin: "35px 0px" } as SxProps,
};
