import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import employeeApi from "../../../../../services/api/Employee";
import { IEmployee } from "../../../../../services/api/Employee/types";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import {
  generalBrightGrey,
  dmPrimaryOrange,
  generalDarkGrey,
  petPrimaryGreen,
  beautyPrimaryPurple,
} from "../../../../../styles/colors";
import { DocType } from "../../../../../types/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../../utils/stringUtils";

const Employees = () => {
  const navigate = useNavigate();

  cleanLocalStorage();

  const [stores, setStores] = useState<IStore[]>([]);
  const [employees, setEmployees] = useState<Record<string, IEmployee[]>>({});
  const [areStoresLoading, setAreStoresLoading] = useState(true);

  const auth = useAuth();

  const fetchStores = async () => {
    try {
      const allStores = await storeApi.getManagerStores(auth.user.id);
      setStores(allStores);
    } catch (error) {
      console.warn("Error getting all stores: ", error);
    }
    setAreStoresLoading(false);
  };

  const fetchEmployees = async (storeId: string) => {
    try {
      const specificEmployees = await storeApi.getEmployeesFromStore(storeId);
      setEmployees((prevEmployees) => ({
        ...prevEmployees,
        [storeId]: specificEmployees,
      }));
    } catch (error) {
      console.warn("Error getting employees:", error);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [auth]);

  useEffect(() => {
    stores.forEach((store) => {
      fetchEmployees(store.id);
    });
  }, [stores]);

  const handleSelectEmployee = (employee: IEmployee) => {
    localStorage.setItem("selectedEmployee", JSON.stringify(employee));
  };

  const handleAddEmployeeClick = (store: IStore) => {
    localStorage.setItem("selectedStore", JSON.stringify(store));
    navigate("/employees/add");
  };

  const handleSeeEmployeeDetails = (employee: IEmployee) => {
    handleSelectEmployee(employee);
    navigate("/employees/details");
  };

  const handleEditEmployeeClick = (employee: IEmployee, store: IStore) => {
    handleSelectEmployee(employee);
    localStorage.setItem("selectedStore", JSON.stringify(store));
    navigate("/employees/edit");
  };

  const handleDeleteEmployee = async (employee: IEmployee, storeId: string) => {
    const employeeId: string = employee.id;
    const wantToDelete: boolean = window.confirm(
      `Tem certeza que deseja excluir ${employee.User.name}?`
    );

    if (wantToDelete) {
      try {
        await employeeApi.deleteEmployee(employee.id);
        console.log(`Employee ${employeeId} deleted with success.`);
        fetchEmployees(storeId);
      } catch (error) {
        console.error("Error deleting the employee: ", error);
      }
    }
  };

  const renderEmployeePageContent = (
    <Box>
      {!areStoresLoading ? (
        <>
          {stores.length !== 0 ? (
            stores.map((store) => (
              <Box key={store.id}>
                <CEOInterfaceHeader
                  title={`Funcionários de ${store.name}`}
                  buttonLabel="Adicionar funcionário"
                  buttonIcon={<PersonAddIcon />}
                  onClick={() => {
                    handleAddEmployeeClick(store);
                  }}
                />
                <Box width="100%">
                  {employees[store.id] && employees[store.id].length > 1 ? (
                    employees[store.id].map((employee) =>
                      employee.User.id !== auth.user.id ? (
                        <TableContainer sx={styles.employeeItemContainer}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell align="center">Documento</TableCell>
                                <TableCell align="right">Ações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key={employee.User.id}>
                                <TableCell>{employee.User.name}</TableCell>
                                <TableCell align="center">
                                  {employee.User.docType === DocType.CPF
                                    ? `${employee.User.docNumber} (CPF)`
                                    : `${employee.User.docNumber} (CNPJ)`}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Detalhes">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        handleSeeEmployeeDetails(employee);
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>{" "}
                                  </Tooltip>
                                  <Tooltip title="Editar escala de trabalho">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        handleEditEmployeeClick(
                                          employee,
                                          store
                                        );
                                      }}
                                    >
                                      <EditCalendarIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Excluir">
                                    <IconButton
                                      color="primary"
                                      onClick={async () => {
                                        await handleDeleteEmployee(
                                          employee,
                                          store.id
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null
                    )
                  ) : (
                    <Box sx={styles.noEmployeeRegisteredContainer}>
                      <Typography variant="body1" color={generalDarkGrey}>
                        Nenhum funcionário cadastrado neste estabelecimento.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <>
              <Typography color={generalDarkGrey}>
                Nenhum estabelecimento cadastrado. Para cadastrar funcionários é
                necessário{" "}
                <span
                  style={{
                    color:
                      CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
                        ? beautyPrimaryPurple
                        : petPrimaryGreen,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/stores/add");
                  }}
                >
                  cadastrar um estabelecimento
                </span>
                .
              </Typography>
            </>
          )}
        </>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden", height: "100vh" }}>
      <CEODrawer content={renderEmployeePageContent} />
    </Box>
  );
};

export default Employees;

const styles = {
  employeeItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  },

  priceHeaderContainer: {
    "@media(max-width:900px)": { display: "none" },
  },

  excursionItemContent: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },

  avatarStyle: {
    width: "80px",
    height: "80px",
    borderRadius: 0,
  },

  excursionPriceContainer: {
    "@media(max-width:900px)": {
      display: "none",
    },
  },

  noEmployeeRegisteredContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 30px 0px",
  },

  registerAnAgencySpan: {
    color: dmPrimaryOrange,
    fontWeight: "bold",
    cursor: "pointer",
  },

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
