import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { BrazilianAddress } from "../types/types";
import {
  cleanAddressRefs,
  fetchAddressByCEP,
  formatCEP,
} from "../utils/cepUtils";

const useCep = (
  cep: string,
  streetRef: MutableRefObject<HTMLInputElement>,
  districtRef: MutableRefObject<HTMLInputElement>,
  cityRef: MutableRefObject<HTMLInputElement>,
  stateRef: MutableRefObject<HTMLInputElement>
) => {
  const [foundedCepAddress, setFoundedCepAddress] =
    useState<BrazilianAddress | null>(null);

  const formattedCep = formatCEP(cep);

  useEffect(() => {
    if (formattedCep.length === 8)
      fetchAddressByCEP(formattedCep).then((foundedAddress) => {
        setFoundedCepAddress(foundedAddress);
        streetRef.current.value = foundedAddress.street;
        districtRef.current.value = foundedAddress.neighborhood;
        cityRef.current.value = foundedAddress.city;
        stateRef.current.value = foundedAddress.state;
      });

    if (foundedCepAddress !== null) {
      setFoundedCepAddress(null);
      cleanAddressRefs(streetRef, districtRef, cityRef, stateRef);
    }
  }, [cep]);

  return foundedCepAddress;
};

export default useCep;
