import { Box, Card, CardContent, Typography } from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";

interface ExcursionServiceCardProps {
  icon: ReactNode;
  serviceType: string;
  service: string;
}

const ExcursionServiceCard = (props: ExcursionServiceCardProps) => {
  const { icon, serviceType, service } = props;

  return (
    <Card variant="outlined" sx={{ width: "100%", borderRadius: "10px" }}>
      <CardContent sx={{ width: "100%" }}>
        <Container>
          {icon}
          <Box>
            <Typography fontWeight={"bold"}>{service}</Typography>
            <Typography>{serviceType}</Typography>
          </Box>
        </Container>
      </CardContent>
    </Card>
  );
};

export default ExcursionServiceCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  padding: 10px;
`;
