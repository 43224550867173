import { Box, SxProps, TextField, Typography } from "@mui/material";
import { CSSProperties, lazy, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
const Alert = lazy(() => import("@mui/material/Alert"));
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { UserRole } from "../../../types/types";
import { loginSchema } from "../../../utils/yupSchemas";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../utils/stringUtils";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestErrorFlag, setRequestErrorFlag] = useState<boolean>(false);

  useEffect(() => {
    if (auth.isLoggedIn) {
      switch (auth.user.role) {
        case UserRole.CLIENT:
          navigate("/home");
          break;
        case UserRole.COMERCIAL:
          CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
          CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
            ? navigate("/stores")
            : navigate("/agencies");
        default:
          break;
      }
    }
  }, [auth]);

  const handleSubmit = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    setRequestErrorFlag(false);
    setIsLoading(true);

    try {
      await auth.authenticate(email, password);
    } catch (error) {
      setRequestErrorFlag(true);
      setIsLoading(false);
      console.warn("Error on user login: ", error);
    }
  };

  useEffect(() => {
    setRequestErrorFlag(false);
  }, [emailRef.current?.value, passwordRef.current?.value]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.title}>
        <Typography variant="h4" fontWeight="bold">
          {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
            ? "Demandoria Beleza"
            : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
              ? "Demandoria PET"
              : "Demandoria Turismo"}
        </Typography>
        <Typography>Conecte-se à sua conta</Typography>
      </Box>

      <form onSubmit={onSubmit(handleSubmit)} style={styles.form}>
        <TextField
          label="Email*"
          type="email"
          inputRef={emailRef}
          {...register("email")}
          error={!!errors.email || requestErrorFlag}
          helperText={errors.email?.message}
          fullWidth
        />

        <Box sx={styles.passwordContainer}>
          <TextField
            label="Senha*"
            type="password"
            inputRef={passwordRef}
            {...register("password")}
            error={!!errors.password || requestErrorFlag}
            helperText={errors.password?.message}
            fullWidth
          />
          <Typography
            onClick={() => {
              navigate("/password-recovery");
            }}
            sx={{
              ...styles.optionSpan,
              alignSelf: "flex-end",
              color: "text.secondary",
            }}
            component={"span"}
            variant="body2"
          >
            Esqueceu sua senha?
          </Typography>
        </Box>

        <Box sx={{ ...styles.title, marginTop: "-10px" }}>
          {requestErrorFlag ? (
            <Alert severity="error" sx={{ width: "100%", padding: "0px 10px" }}>
              Email ou senha inválidos.
            </Alert>
          ) : null}
        </Box>
        <Box width="100%">
          <PrimaryButton
            label="Entrar"
            icon={<LoginIcon />}
            isLoading={isLoading}
          />
        </Box>
      </form>

      <Box>
        <Typography>
          Usuário novo?{" "}
          <Typography
            onClick={() => {
              navigate("/register");
            }}
            sx={{ ...styles.optionSpan, color: "primary.main" }}
            component={"span"}
          >
            Cadastre-se
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;

const styles = {
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    padding: "0px 30px",
  } as SxProps,

  title: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as SxProps,

  form: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  } as CSSProperties,

  passwordContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  } as SxProps,

  optionSpan: {
    cursor: "pointer",
    fontWeight: "bold",
  } as SxProps,
};
