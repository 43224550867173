import { IconButton, MenuItem } from "@mui/material";
import { ReactNode } from "react";

interface MobileMenuItemProps {
  onClick: () => void;
  icon: ReactNode;
  label: ReactNode;
}

const MobileMenuItem = (props: MobileMenuItemProps) => {
  const { onClick, icon, label } = props;

  return (
    <MenuItem onClick={onClick}>
      <IconButton size="large">{icon}</IconButton>
      {label}
    </MenuItem>
  );
};

export default MobileMenuItem;
