import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IEmployee } from "../../services/api/Employee/types";
import employeeApi from "../../services/api/Employee";

const useSearchEmployees = (search: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [employeesLoading, setEmployeesLoading] = useState<boolean>(false);

  const handleSearchEmployees = async () => {
    setEmployeesLoading(true);
    try {
      const searchResponse = await employeeApi.searchEmployees(search);
      setEmployees(searchResponse);
    } catch (error) {
      console.warn("Error searching employees: ", error);
    }
    setEmployeesLoading(false);
  };

  const executeSearch = async () => {
    setSearchParams({ employee: search });

    switch (search.length) {
      case 0:
        setSearchParams({});
        setEmployees([]);
        break;
      default:
        handleSearchEmployees();
        break;
    }
  };

  useEffect(() => {
    executeSearch();
  }, [search]);

  return { employees, employeesLoading };
};

export default useSearchEmployees;
