import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import storeApi from "../../services/api/Store";
import { IStore } from "../../services/api/Store/types";

const useSearchStores = (search: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [stores, setStores] = useState<IStore[]>([]);
  const [storesLoading, setStoresLoading] = useState<boolean>(false);

  const handleSearchStores = async () => {
    setStoresLoading(true);
    try {
      const searchResponse = await storeApi.searchStores(search);
      setStores(searchResponse);
    } catch (error) {
      console.warn("Error searching stores: ", error);
    }
    setStoresLoading(false);
  };

  const executeSearch = () => {
    setSearchParams({ store: search });

    switch (search.length) {
      case 0:
        setSearchParams({});
        setStores([]);
        break;

      default:
        handleSearchStores();
        break;
    }
  };

  useEffect(() => {
    executeSearch();
  }, [search]);

  return { stores, storesLoading };
};

export default useSearchStores;
