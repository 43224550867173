import { Dayjs } from "dayjs";
import { IPromotionObject } from "../../../../services/api/Appointment/types";
import { formatDateString } from "../../../../utils/stringUtils";

export const getPromotionalDatesToHighlight = (
  promotionalResults: IPromotionObject[]
) => {
  const highlightedDays = [];

  promotionalResults.forEach((promotion) => {
    highlightedDays.push(promotion.date);
  });

  return highlightedDays;
};

export const getSingleDatePromotionSchedule = (
  selectedDate: Dayjs,
  schedule: any[]
) => {
  const formattedSelectedDate = selectedDate.format("DD/MM/YYYY");

  const selectedDateSchedule = schedule.filter(
    (schedulesPerDay) =>
      formatDateString(schedulesPerDay?.result[0]?.times[0]?.value) ==
      formattedSelectedDate
  )[0]?.result;

  return selectedDateSchedule;
};
