import { useEffect, useState } from "react";
import ManagerStoresContext from ".";
import { IStore } from "../../services/api/Store/types";

const ManagerStoresProvider = ({ children }: any) => {
  const [managerStores, setManagerStores] = useState<IStore[] | null>(null);

  useEffect(() => {
    console.log(managerStores);
  }, [managerStores]);

  return (
    <ManagerStoresContext.Provider
      value={{ stores: managerStores, setStores: setManagerStores }}
    >
      {children}
    </ManagerStoresContext.Provider>
  );
};

export default ManagerStoresProvider;
