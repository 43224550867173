import StoreIcon from "@mui/icons-material/Store";
import ExploreIcon from "@mui/icons-material/Explore";
import { SxProps } from "@mui/material";

const singleTabStyle: SxProps = {
  textTransform: "none",
  color: "rgba(0,0,0,0.4)",
};

const tabsInfo = [
  { label: "Excursões", icon: <ExploreIcon />, style: singleTabStyle },
  { label: "Agências", icon: <StoreIcon />, style: singleTabStyle },
];

export default tabsInfo;
