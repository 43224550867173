import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { POSSIBLE_WORKING_TIMES } from "../../workingTimeConstants";
import {
  addAndEditStoreSchema,
  showCustomInputFieldError,
  validateCnpj,
  validatePhoneNumber,
} from "../storeValidationSchemas";
import InputMask from "react-input-mask";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import CEODrawer from "../../../../../components/pet-beauty/CEODrawer";
import CEOInterfaceHeader from "../../../../../components/comercial/CEOInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import {
  AddressProps,
  getCoordinatesByAddress,
} from "../../../../../hooks/getCoodinatesByAddress";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import { generalDarkGrey, generalWhite } from "../../../../../styles/colors";
import {
  deleteImageFromFirebase,
  uploadImagesToFirebase,
} from "../../../../../utils/firebase";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";
import useCep from "../../../../../hooks/useCep";
import { sharedStyles } from "../../styles";
import BackPageButton from "../../../../../components/general/buttons/BackPageButton";
import { backButtonContainer } from "../../../../../styles/sharedComponentStyles";

const EditStore = () => {
  const navigate = useNavigate();

  const selectedStore: IStore = JSON.parse(
    localStorage.getItem("selectedStore")!
  );

  const storeNameRef = useRef<HTMLInputElement>(null);
  const [cnpj, setCnpj] = useState<string>(selectedStore.CNPJ);
  const cnpjRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState<string>(selectedStore.phone);
  const phoneRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(selectedStore.CEP);

  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImages, setOriginalImages] = useState<HTMLCanvasElement[]>([]);
  const [previewImages, setPreviewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedStore.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedStore.imagePreview
  );

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedStore.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedStore.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedStore.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedStore.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedStore.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedStore.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedStore.timeSunday
  );

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditStoreSchema) });

  const handleUpdateStore = async () => {
    setGeneralErrorMessage("");

    if (submittedOnce === false) setSubmittedOnce(true);

    const isCnpjValid = validateCnpj(cnpj);
    const isPhoneValid = validatePhoneNumber(phone);

    if (isCnpjValid && isPhoneValid) {
      setButtonsDisabled(!buttonsDisabled);

      try {
        let iterableOriginalImageUrl = "";
        let iterablePreviewImageUrl = "";

        for (let i = 0; i < selectedStore.imageOriginal.length; i++) {
          iterableOriginalImageUrl = selectedStore.imageOriginal[i];
          iterablePreviewImageUrl = selectedStore.imagePreview[i];

          if (!originalImageUrls.includes(iterableOriginalImageUrl))
            await deleteImageFromFirebase(iterableOriginalImageUrl);

          if (!previewImageUrls.includes(iterablePreviewImageUrl))
            await deleteImageFromFirebase(iterablePreviewImageUrl);
        }

        if (images.length > 0) {
          const originalUuids = await uploadImagesToFirebase(
            originalImages,
            originalImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${storeNameRef?.current?.value}_${selectedStore.id}/store-images`,
            "_original",
            []
          );

          await uploadImagesToFirebase(
            previewImages,
            previewImageUrls,
            `${CURRENT_SUBDOMAIN}/stores/${storeNameRef?.current?.value}_${selectedStore.id}/store-images`,
            "_preview",
            originalUuids
          );
        }

        const coordinatesResponse = await getCoordinatesByAddress({
          street: streetRef?.current?.value,
          number: numberRef?.current?.value,
          district: districtRef?.current?.value,
          city: cityRef?.current?.value,
          state: stateRef?.current?.value,
          country: "Brazil",
          postalCode: cep,
        } as AddressProps);

        const updatedStoreResponse = await storeApi.updateStore(
          selectedStore.id,
          {
            name: storeNameRef?.current?.value,
            email: emailRef?.current?.value,
            CNPJ: cnpj,
            phone: phone,
            description: descriptionRef?.current?.value,
            logradouro: streetRef?.current?.value,
            number: numberRef?.current?.value,
            district: districtRef?.current?.value,
            city: cityRef?.current?.value,
            state: stateRef?.current?.value,
            CEP: cep,
            country: "Brazil",
            lat: Number(coordinatesResponse[0]),
            lng: Number(coordinatesResponse[1]),
            imageOriginal: originalImageUrls,
            imagePreview: previewImageUrls,
            listTags: "",
            timeMonday,
            timeTuesday,
            timeWednesday,
            timeThursday,
            timeFriday,
            timeSaturday,
            timeSunday,
          } as IStore
        );

        window.alert(`${updatedStoreResponse.name} editado com sucesso!`);
        navigate("/stores");
      } catch (error) {
        setButtonsDisabled(false);
        console.warn("Error updating the agency: ", error);
      }
      setButtonsDisabled(buttonsDisabled);
      setGeneralErrorMessage(error.response.data.message);
    } else {
      !isCnpjValid ? cnpjRef.current.focus() : null;
      !isPhoneValid ? phoneRef.current.focus() : null;
    }
  };

  const handleDeleteExistingImageClick = async (index: number) => {
    setOriginalImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    setPreviewImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const handleRemoveNewImageClick = (index: number) =>
    images.length > 0 &&
    setImages((previousImages) => previousImages.filter((_, i) => i !== index));

  const foundedCepAddress = useCep(
    cep,
    streetRef,
    districtRef,
    cityRef,
    stateRef
  );

  const renderStoreData = (
    <>
      <CEOInterfaceHeader title="Dados da agência" />
      <Box sx={sharedStyles.singleFormContainer}>
        <Box sx={sharedStyles.dividedFormContainer}>
          <TextField
            size="small"
            label="Nome da agência *"
            inputRef={storeNameRef}
            defaultValue={selectedStore.name}
            {...register("storeName")}
            error={!!errors.storeName}
            helperText={errors.storeName?.message}
            fullWidth
          />
          <InputMask
            mask="99.999.999/9999-99"
            disabled={false}
            maskChar="_"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            error={showCustomInputFieldError(
              !validateCnpj(cnpj),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(!validateCnpj(cnpj), submittedOnce)
                ? "CNPJ inválido."
                : ""
            }
            inputRef={cnpjRef}
          >
            <TextField size="small" fullWidth label="CNPJ *" />
          </InputMask>
        </Box>

        <Box sx={sharedStyles.dividedFormContainer}>
          <InputMask
            mask="(99) 9.9999-9999"
            disabled={false}
            maskChar="_"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={showCustomInputFieldError(
              !validatePhoneNumber(phone),
              submittedOnce
            )}
            helperText={
              showCustomInputFieldError(
                !validatePhoneNumber(phone),
                submittedOnce
              )
                ? "Telefone inválido."
                : ""
            }
            inputRef={phoneRef}
          >
            <TextField label="Telefone *" size="small" fullWidth />
          </InputMask>
          <TextField
            size="small"
            label="Email para contato *"
            placeholder="Ex.: contato@email.com"
            fullWidth
            inputRef={emailRef}
            defaultValue={selectedStore.email}
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>
        <TextField
          size="small"
          label="Descrição"
          placeholder="Descreva brevemente sua agência..."
          defaultValue={selectedStore.description}
          fullWidth
          multiline
          rows={5}
          inputRef={descriptionRef}
        />
      </Box>
    </>
  );

  const renderAddressData = (
    <>
      <CEOInterfaceHeader title="Dados geográficos da agência" />
      <Box sx={sharedStyles.singleFormContainer}>
        <InputMask
          mask="99999-999"
          defaultValue={selectedStore?.CEP}
          disabled={false}
          maskChar="_"
          value={cep}
          onChange={(e) => {
            setCep(e.target.value);
          }}
        >
          {<TextField size="small" label="CEP" fullWidth />}
        </InputMask>
        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Logradouro"
            defaultValue={selectedStore?.logradouro}
            inputRef={streetRef}
            sx={{ width: "75%" }}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
          <TextField
            size="small"
            label="N°"
            defaultValue={selectedStore?.number}
            inputRef={numberRef}
            sx={{ width: "25%" }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <TextField
          size="small"
          label="Bairro"
          defaultValue={selectedStore?.district}
          fullWidth
          inputRef={districtRef}
          InputLabelProps={{ shrink: true }}
          disabled={foundedCepAddress !== null}
        />

        <Box sx={{ ...sharedStyles.singleFormContainer, flexDirection: "row" }}>
          <TextField
            size="small"
            label="Cidade"
            defaultValue={selectedStore?.city}
            disabled={foundedCepAddress !== null}
            fullWidth
            inputRef={cityRef}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            size="small"
            label="Estado"
            defaultValue={selectedStore?.state}
            fullWidth
            inputRef={stateRef}
            InputLabelProps={{ shrink: true }}
            disabled={foundedCepAddress !== null}
          />
        </Box>
      </Box>
    </>
  );

  const renderImageGalery = (
    <>
      <ImageGalleryHeader
        label="Adicionar"
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
        originalImages={originalImages}
        previewImages={previewImages}
      />
      <Box display="flex" flexDirection="column" gap={2} margin="25px">
        {images.length > 0 || originalImageUrls.length > 0 ? (
          <ImageList
            sx={{ marginBottom: "40px" }}
            cols={3}
            variant="quilted"
            rowHeight={300}
          >
            {originalImageUrls.length > 0 ? (
              originalImageUrls.map((imageUrl, index) => (
                <ImageListItem key={index}>
                  <img src={imageUrl} alt={`Image ${index}`} loading="lazy" />
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                        "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        sx={{ color: generalWhite }}
                        onClick={() => handleDeleteExistingImageClick(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))
            ) : (
              <></>
            )}
            {images.map((image, index) => (
              <ImageListItem key={index}>
                <img
                  src={image.toDataURL()}
                  alt={`Image ${index}`}
                  loading="lazy"
                />

                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: generalWhite }}
                      onClick={() => handleRemoveNewImageClick(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            color="text.secondary"
            margin="70px 0px"
          >
            <Box>
              <Typography textAlign="justify" color={generalDarkGrey}>
                Adicione imagens do seu estabelecimento.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );

  const renderWorkScaleSelector = (
    <>
      <CEOInterfaceHeader title="Horários de funcionamento" />
      <Box>
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={POSSIBLE_WORKING_TIMES}
          possibleTuesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleWednesdayTimes={POSSIBLE_WORKING_TIMES}
          possibleThursdayTimes={POSSIBLE_WORKING_TIMES}
          possibleFridayTimes={POSSIBLE_WORKING_TIMES}
          possibleSaturdayTimes={POSSIBLE_WORKING_TIMES}
          possibleSundayTimes={POSSIBLE_WORKING_TIMES}
        />
      </Box>
    </>
  );

  const renderEditStorePageContent = (
    <>
      <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
        <BackPageButton />
      </Box>
      <form onSubmit={onSubmit(handleUpdateStore)} style={sharedStyles.form}>
        {renderStoreData}
        {renderAddressData}
        {renderImageGalery}
        {renderWorkScaleSelector}
        {generalErrorMessage !== "" ? (
          <Alert severity="error">{generalErrorMessage}</Alert>
        ) : null}
        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar edição"
            isDisabled={buttonsDisabled}
            icon={<EditIcon />}
            isLoading={buttonsDisabled}
          />
          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/stores")}
            isDisabled={buttonsDisabled}
          />
        </Box>
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <CEODrawer content={renderEditStorePageContent} />
    </Box>
  );
};

export default EditStore;
