import Tabs from "@mui/material/Tabs/Tabs";

import { Dispatch, SetStateAction } from "react";
import { SxProps, Tab } from "@mui/material";

interface SearchTabsProps {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  tabs: any[];
}

const SearchTabs = (props: SearchTabsProps) => {
  const { value, setValue, tabs } = props;

  const handleChangeTabIndex = (_, newValue: number) => setValue(newValue);

  return (
    <Tabs
      value={value}
      onChange={handleChangeTabIndex}
      centered
      variant="fullWidth"
      sx={styles.tabs}
      textColor="secondary"
      indicatorColor="secondary"
    >
      {tabs.map((tab, index) => (
        <Tab label={tab.label} icon={tab.icon} sx={tab.style} key={index} />
      ))}
    </Tabs>
  );
};

export default SearchTabs;

const styles = {
  tabs: {
    width: "100vw",
    backgroundColor: "primary.main",
    justifyContent: "space-around",
  } as SxProps,
};
